import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { useMemo } from 'react';
import { FEATURE_FLAGS_CO_SELL_V3_UI } from 'packages/cosell/src/utilities/constants/CoSellFeatureFlags';

type InvitationFeatures = 'read' | 'close';
type OpportunityFeatures =
  | 'read'
  | 'create'
  | 'edit'
  | 'submit-to-cloud'
  | 'close';

type FeatureFlagPayload = {
  invitations?: Record<InvitationFeatures, boolean>;
  opportunities?: Record<OpportunityFeatures, boolean>;
};

/**
 * A feature flag utility hook that wraps the useAmpliFeatureFlags hook,
 * but for a specific set of AWS features, which are stored as variants of the feature flag.
 *
 * returns a boolean for every possible gated feature in the AWS Co-sell workflow.
 *
 */
export function useCanUseAwsFeatures() {
  const { loading, error, flagPayloads } = useAmpliFeatureFlags();

  const awsFeatureAccess: FeatureFlagPayload = useMemo(() => {
    return flagPayloads[FEATURE_FLAGS_CO_SELL_V3_UI.AWS_FEATURES_ENABLED] ?? {};
  }, [flagPayloads]);

  /** returns all feature access statuses */
  const canUse = useMemo(() => {
    const { invitations, opportunities } = awsFeatureAccess;
    return {
      invitations: {
        view: !!invitations?.read,
        close: !!invitations?.close,
      },
      opportunities: {
        view: !!opportunities?.read,
        create: !!opportunities?.create,
        close: !!opportunities?.close,
        edit: !!opportunities?.edit,
        submitToCloud: !!opportunities?.['submit-to-cloud'],
      },
    };
  }, [awsFeatureAccess]);

  return {
    canUse,
    error,
    loading,
  };
}
