import {
  CanvasBulkCreateCosell,
  CanvasCreateCoSell,
  CanvasCoSellDetails,
} from '../cosell/src';
import { Loader } from '@tackle-io/platform-ui';
import { useCanvasSession } from './useCanvasSession';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import CanvasTackleIntegrationStatus from './components/CanvasTackleIntegrationStatus';

const CanvasApp = () => {
  const { canvasSession, isLoadingCanvasSession } = useCanvasSession();
  const classes = useCanvasAppStyles();

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  const componentId = canvasSession?.context?.parameters.componentId;

  if (!componentId) {
    return <div>No context provided.</div>;
  }

  const getComponentToRender = () => {
    switch (componentId) {
      case 'CoSellDetails':
        return <CanvasCoSellDetails />;
      case 'CreateCoSell':
        return <CanvasCreateCoSell />;
      case 'BulkCreateCoSell':
        return <CanvasBulkCreateCosell />;
      case 'Setup':
        return <CanvasTackleIntegrationStatus />;
      default:
        return <div>Unknown componentId: {componentId} provided.</div>;
    }
  };

  return (
    <AwsOpportunityActionsProvider token={canvasSession.access_token}>
      <div className={classes.canvasAppContainer}>{getComponentToRender()}</div>
    </AwsOpportunityActionsProvider>
  );
};

const CanvasAppWithSessionProvider = () => {
  return (
    <ReactQueryClientProvider>
      <CanvasApp />
    </ReactQueryClientProvider>
  );
};

export default CanvasAppWithSessionProvider;
