import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { InputLabel, FormControl, FormHelperText } from 'vendor/material';
import Alert from 'mdi-material-ui/Alert';
import NumberFormat from 'react-number-format';
import { useTextFieldStyles, TextInputBase } from './TextField.styles';
import { idGenerator } from '../../../utils/index';

const generatedId = idGenerator('TextField');

const TextField = ({
  error = undefined,
  hasError = false,
  helperText = undefined,
  type = undefined,
  label = undefined,
  secondaryLabel = undefined,
  labelHidden = undefined,
  id = generatedId(''),
  disabled = false,
  readOnly = false,
  name,
  iconLeft = undefined,
  iconRight = undefined,
  append = undefined,
  maxLength = undefined,
  onFocus = (e = null) => {},
  onBlur = (e = null) => {},
  onChange = undefined,
  placeholder = undefined,
  withCopyToClipboard = false,
  inputProps = undefined,
  mode = '',
  decimalScale = 2,
  numberPrefix = '$ ',
  size = 'medium',
  valueAccessor = () => {},
  ...props
}) => {
  const { value } = props;
  const [numberFormat, setNumberFormat] = useState({
    value,
    focused: false,
  });

  const handleRef = (el) => {
    if (props.inputRef) {
      if (typeof props.inputRef === 'function') {
        props.inputRef(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        props.inputRef.current = el;
      }
    }
  };

  const showErrorState = Boolean(error) || hasError;
  const showErrorMessage = !!error;

  useEffect(() => {
    if (mode === 'numberformat' && value !== numberFormat.value) {
      setNumberFormat((state) => ({ ...state, value }));
    }
  }, [mode, value, numberFormat.value]);

  const handleChange = (event) => {
    if (event.nativeEvent) {
      event.persist();
    }

    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const classes = useTextFieldStyles();
  const cssInputBase = classNames(classes.inputBase, {
    [classes.inputDisabled]: disabled || readOnly,
    [classes.withAppend]: append,
    [classes.withIconLeft]: iconLeft,
    [classes.withIconRight]: iconRight || type === 'password',
    [classes.inputError]: showErrorState,
    [classes.smallSize]: size === 'small',
    [classes.checkbox]: type === 'checkbox',
  });

  const cssInputContainer = classNames(classes.inputContainer, {
    [classes.inputContainerError]: showErrorState,
    'input-type-number': type === 'number',
  });

  const cssInputLabel = classNames(classes.inputLabel, {
    [classes.inputLabelFocused]: numberFormat.focused,
  });

  const cssHelperTextContainer = classNames(classes.helperTextContainer, {
    [classes.helperTextDisabled]: disabled,
  });

  return (
    <FormControl fullWidth disabled={disabled} error={showErrorState}>
      {!labelHidden && (
        <InputLabel classes={{ root: cssInputLabel }} shrink htmlFor={id}>
          {label}{' '}
          <span className={classes.secondaryLabel}>{secondaryLabel}</span>
        </InputLabel>
      )}
      <div className={cssInputContainer}>
        <div className={classes.iconLeft}>{iconLeft}</div>
        {!mode && (
          <TextInputBase
            id={id}
            type={type}
            className={cssInputBase}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            name={name}
            inputProps={inputProps}
            {...props}
            value={value}
            inputRef={handleRef}
          />
        )}
        {mode === 'numberformat' && (
          <>
            <NumberFormat
              {...props}
              name={name}
              id={id}
              readOnly={readOnly}
              disabled={disabled}
              value={numberFormat.value}
              decimalScale={decimalScale}
              thousandSeparator
              isNumericString
              prefix={numberPrefix}
              onFocus={() => {
                setNumberFormat((state) => {
                  return { ...state, focused: true };
                });
              }}
              onBlur={() => {
                setNumberFormat((state) => {
                  return { ...state, focused: false };
                });
              }}
              onValueChange={(values) => {
                setNumberFormat((state) => {
                  return { ...state, value: values.floatValue };
                });
                if (onChange) {
                  onChange({
                    target: { value: values.floatValue },
                  });
                }
              }}
            />
          </>
        )}

        {append}
      </div>
      <div className={classes.footerContainer}>
        <div className={cssHelperTextContainer}>
          {!showErrorMessage && helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
          {showErrorMessage && (
            <div className={classes.errorContainer}>
              <Alert fontSize="inherit" />
              <FormHelperText>{error}</FormHelperText>
            </div>
          )}
        </div>
      </div>
    </FormControl>
  );
};

export default TextField;
