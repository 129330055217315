import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';

const useInvitationsListQuery = () => {
  const {
    data: invitationsList,
    error: invitationsListError,
    isLoading: isInvitationsListLoading,
  } = useQuery({
    queryKey: ['cosell-invitations-list', 'ace', {}],
    queryFn: coSellClient.getInvitationsList,
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
  });

  return {
    invitationsList,
    invitationsListError,
    isInvitationsListLoading,
    total: invitationsList?.total,
    isNextPage: invitationsList?.isNextPage,
    currentPageCount: invitationsList?.currentPageCount,
    nextIndex: invitationsList?.nextIndex,
  };
};

export default useInvitationsListQuery;
