import { DateTime } from 'luxon';
import { filter, flow, map } from 'lodash/fp';

import {
  APIErrorResponseBody,
  APISuccessResponse as APISuccessRes,
  Cloud,
  ReducerState,
} from 'stores/typings';
import {
  camelCase,
  convertKeysTo,
  convertValuesTo,
  dateTimeToString,
  snakeCase,
  stringToDateTime,
} from 'stores/utils';

import emptyStringToNull from 'utils/emptyStringToNull';

export interface DeserializedFiles {
  eulaFiles?: string[];
  resellerAgreementFiles?: string[];
}

export interface UploadListEulaFile extends File {
  canonicalName: string;
}

export interface UploadListResellerAgreementFile extends File {
  canonicalName: string;
}

export interface PrivateOfferJSON {
  po_ref?: string;
  id?: string;
  po_id: string;
  vendor_id: string;
  cloud: string | null;
  product_ref: string | null;
  offer_ref: string | null;
  offer_type?: string | null;
  customer_ref: string | null;
  salesforce_ref: string | null;
  abo_linked_offer_ref?: string;

  users_to_notify: UsersToNotifyJSON[];
  metadata: {
    pre_registration_details?: { [key: string]: any };
    buyers?: {
      full_name: string;
      email_address: string;
      title?: string;
    }[];
    pricing?: MetadataPricingJSON;
    offer_metadata: { [key: string]: any };
    offer_expiration_at?: string;
    offer_start_at?: string;
    offer_end_at?: string;
    offer_point_of_contact?: string;
    offer_point_of_contact_name?: string;
    eula?: {
      type: string;
      files?: string[];
      document_urns?: string[];
    };
    renewal?: boolean;
    enable_zero_dollar_prices?: boolean;
    buyer_billing_account_ref?: string | null;
    marketplace_acceptance_link?: string;
  };
  activities: ActivityJSON[];

  pricing?: RedHatPricingJSON;

  created_at: string;
  last_modified_at: string;
  last_update_source: string;

  sent_at: string | null;
  accepted_at: string | null;
  accepted_source: string;
  archived_at: string | null;
  viewed_offer_at: string | null;
  cancelled_at?: string;
  opened_instructions_at?: string;
  partner_offer?: PartnerInfoJSON;
  partner_offer_ref?: string | null;
}

export interface FilesJSON {
  eulaFiles?: string[];
  resellerAgreementFiles?: string[];
}

export interface FileUrlMap {
  [key: string]: {
    url: URL;
    original_name: string;
  };
}

export interface PrivateOffer {
  poId: string;
  clonedPoId: string;
  vendorId: string;
  cloud: Cloud;
  offerType: OfferType;
  productRef: string | null;
  offerRef: string | null;
  customerRef: string | null;
  salesforceRef: string | null;
  aboLinkedOfferRef: string | null;

  usersToNotify: UsersToNotify[];
  metadata: {
    preRegistrationDetails?: { [key: string]: any };
    buyers: {
      fullName: string;
      emailAddress: string;
      title?: string | null;
    }[];
    offerMetadata: { [key: string]: any };
    pricing?: MetadataPricing;
    enableZeroDollarPrices: boolean;
    eula?: Eula;
    renewal: boolean;
    offerExpirationAt?: string;
    offerStartAt?: string;
    offerEndAt?: string;
    offerPointOfContact?: string;
    offerPointOfContactName?: string;
    buyerBillingAccountRef?: string | null;
    marketplaceAcceptanceLink?: string;
  };
  activities: Activity[];

  pricing?: RedHatPricing;

  createdAt: DateTime;
  lastModifiedAt: DateTime;
  lastUpdatedSource: string;

  sentAt: DateTime | null;
  acceptedAt: DateTime | null;
  acceptedSource: string;
  archivedAt: DateTime | null;
  viewedOfferAt: string | null;
  partnerOffer?: PartnerInfo;
  partnerOfferRef?: string | null;
}

export interface Agreement {
  status?: AgreementStatus;
  start?: DateTime;
  end?: DateTime;
  tackleMappedOffer?: PrivateOffer;
}

export enum AgreementStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Renewed = 'RENEWED',
  Replaced = 'REPLACED',
  RolledBack = 'ROLLED_BACK',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
}

export interface AWSAgreementViewSummaryJSON {
  Status?: AgreementStatus;
  StartTime?: string;
  EndTime?: string;

  // available fields, but not yet used
  // AcceptanceTime?: string;
  // Acceptor?: {
  //   AccountId?: string;
  // };
  // AgreementId?: string;
  // AgreementType?: string;
  // ProposedSummary?: {
  //   OfferId?: string;
  //   Resources: {
  //     Id?: string;
  //     Type?: string;
  //   }[];
  // };
  // Proposer?: {
  //   AccountId?: string;
  // };
}

interface AWSMarketplaceOfferResponseDataMarketplaceJSON {
  agreement?: AWSAgreementViewSummaryJSON;
  offer: any;
}

interface LicenseJSON {
  eula_url: string;
}

interface ProductJSON {
  productid: string;
  license: LicenseJSON;
  encrypted_productid: string;
}

export interface MarketplaceOfferResponseDataTackleMappedJSON {
  offer: PrivateOfferJSON;
  product: ProductJSON;
}

export interface MarketplaceOfferResponseDataJSON {
  marketplace: AWSMarketplaceOfferResponseDataMarketplaceJSON;
  tackle_mapped: MarketplaceOfferResponseDataTackleMappedJSON;
}

export interface MarketplaceOfferResponseJSON {
  data: MarketplaceOfferResponseDataJSON;
}

export interface AWSAgreementViewSummary {
  status: AgreementStatus;
  startTime?: DateTime;
  endTime?: DateTime;

  // available fields, but not yet used
  // acceptanceTime?: DateTime;
  // acceptor?: {
  //   accountId?: string;
  // };
  // agreementId?: string;
  // agreementType: string;
  // proposedSummary?: {
  //   offerId?: string;
  //   resources: {
  //     id?: string;
  //     type?: string;
  //   }[];
  // };
  // proposer?: {
  //   accountId?: string;
  // };
}

export interface MarketplaceOfferMarketplaceData {
  agreement?: AWSAgreementViewSummary;
  offer: any;
}

interface License {
  eulaUrl: string;
}

export interface Product {
  productid: string;
  license: License;
  encryptedProductid: string;
}

export interface MarketplaceOfferTackleMappedData {
  offer: PrivateOffer;
  product: Product;
}

export interface MarketplaceOffer {
  marketplace: MarketplaceOfferMarketplaceData;
  tackleMapped: MarketplaceOfferTackleMappedData;
}

const awsMarketplaceOfferDateTimeKeys = [
  'marketplace.agreement.acceptanceTime',
  'marketplace.agreement.endTime',
  'marketplace.agreement.startTime',
];

export const deserializeMarketplaceOffer = (
  json: MarketplaceOfferResponseDataJSON,
): MarketplaceOffer => {
  const marketplaceOffer = convertKeysTo(camelCase)(json);
  const convertedDateTimes = stringToDateTime(
    awsMarketplaceOfferDateTimeKeys,
    marketplaceOffer,
  );

  return {
    ...marketplaceOffer,
    ...convertedDateTimes,
  } as MarketplaceOffer;
};

export type EULAType =
  | 'public' // aws only; from listing's public offer eula
  | 'aws-standard' // legacy for standard aws eula; eventually should migrate to 'standard'
  | 'standard' // standard marketplace eula
  | 'use-existing' // aws only; to reuse same eula from previous agreement
  | 'custom' // only uploaded files from ISV used
  | string; // included for backwards compatibility or if there is bad data

export interface Eula {
  type: EULAType; // used by aws, gcp
  files: string[];
  documentUrns: string[];
}

export interface PrivateOfferDocumentURLs {
  eula: { [urn: string]: string };
  resellerAgreement: { [urn: string]: string };
}

export interface PrivateOfferDocumentURLsResponse {
  data: PrivateOfferDocumentURLs;
}

export interface UsersToNotify {
  contactType: ContactType;
  value: string;
}

export interface UsersToNotifyJSON {
  contact_type: string;
  value: string;
}

export enum UsersToNotifyType {
  Auth0 = 'auth0',
  Email = 'email',
}

export type ContactType = UsersToNotifyType.Auth0 | UsersToNotifyType.Email;

export type PrivateOfferPricing = RedHatPricing | MetadataPricing;

export enum OfferType {
  Direct = 'direct',
  PartnerResale = 'partner_resale',
}

export interface PricingCalculatedValuesJSON {
  total_contract_value?: string | number;
  net_contract_value?: string | number;
  monthly_contract_value?: string | number;
}

export interface PricingCalculatedValues {
  totalContractValue?: string | number;
  monthlyContractValue?: string | number;
  netContractValue?: string | number;
}

export interface MetadataPricingJSON extends PricingCalculatedValuesJSON {
  dimensions: DimensionJSON[];
  units?: UnitJSON[];
  usage_dimensions?: UsageDimensionJSON[];
  duration?: string | number;
  schedule?: ScheduleJSON[];
  marketplace_fee?: string;
  show_on_email: boolean;
  version: string;
  service_start_at?: string;
  service_end_at?: string;
  currency_code?: string;
}

export interface MetadataPricing extends PricingCalculatedValues {
  // Modal User Input
  dimensions:
    | Dimension[]
    | AzureOfferPlan[]
    | AzureOfferPlanMarketplacePricingV1[];
  units?: Unit[];
  usageDimensions?: UsageDimension[];
  duration?: string | number;
  schedule?: Schedule[];
  marketplaceFee?: string;

  // Checkbox User Input
  showOnEmail: boolean;

  // pre-defined
  version: string;

  paymentModel?: PaymentModelValueType;
  billingTerm?: BillingTermValueType;
  durationValue?: number;
  durationType?: DurationTypeValue;
  serviceStartAt?: string;
  serviceEndAt?: string;
  currencyCode?: string;
}

export interface RedHatPricingJSON extends PricingCalculatedValuesJSON {
  pricing_type?: string;
  edition_id?: string;
  billing_frequency?: string;
  offer_terms?: RedHatOfferTermJSON[];
  marketplace_fee?: string;
}

export interface RedHatOfferTermJSON {
  term: string;
  term_unit: string;
  charges: RedHatPricingOfferTermChargeJSON[];
}

export interface RedHatPricingOfferTermChargeJSON {
  id: string;
  quantity: string;
  unit_price: string;
}

export interface RedHatPricing extends PricingCalculatedValues {
  pricingType?: string;
  editionId?: string;
  billingFrequency?: string | null;
  offerTerms?: RedHatPricingOfferTerm[];
  marketplaceFee?: string;
}

export interface RedHatPricingOfferTerm {
  term: string;
  termUnit: string;
  charges: RedHatPricingOfferTermCharge[];
}

export interface RedHatPricingOfferTermCharge {
  id: string;
  quantity: string;
  unitPrice: string;
}

export interface DimensionJSON {
  name: string;
  price?: string;
  quantity?: string | null;
  dimension_value?: string;
  description?: string | null;
  api_name?: string | null;
}

export interface Dimension {
  name: string;
  price: string;
  quantity?: string | null;
  dimensionValue?: string; // "calculated" to be removed
  description?: string | null;
  apiName?: string | null;
}

export interface AzureOfferPlan {
  name: string;
  absolutePrices: AzureAbsolutePrice[];
  discountPercentage?: number | null;
}

export type AzureOfferPlanMarketplacePricingV1 = Omit<
  AzureOfferPlan,
  'name'
> & {
  apiName: string;
  name: string;
  description: string;
};

export interface UsageDimensionJSON {
  sku: string;
  price?: string;
  description: string;
  discount_percentage?: string | null;
}

export interface UsageDimension {
  sku: string;
  price?: string;
  description: string;
  discountPercentage?: string | null;
}

export interface UnitJSON {
  unit: string;
  hourly_price: number;
  annual_price: number;
}

export interface Unit {
  unit: string;
  hourlyPrice: number;
  durationPrice: number;
}

export enum ScheduleInvoiceDateType {
  OnAcceptance = 'on-acceptance',
  OnStartDate = 'on-start-date',
  Custom = 'custom',
}

export const ScheduleInvoiceDateTypeToDisplayLabel: Record<
  ScheduleInvoiceDateType,
  string
> = {
  [ScheduleInvoiceDateType.OnAcceptance]: 'On Acceptance',
  [ScheduleInvoiceDateType.OnStartDate]: 'On Start Date',
  [ScheduleInvoiceDateType.Custom]: '', // Date is displayed if custom type
};

export interface ScheduleJSON {
  invoice_amount?: string;
  invoice_date?: string;
  invoice_date_type?: string;
}

export interface Schedule {
  invoiceAmount?: string;
  invoiceDate?: string;
  invoiceDateType?: ScheduleInvoiceDateType;
}

export interface ActivityJSON {
  activity_type: string;
  slug: string;
  created_at: string;
  user_id: string;
  metadata: {
    [key: string]: any;
  } | null;
}

export interface Activity {
  activityType: string;
  slug: string;
  createdAt: DateTime;
  userId: string;
  metadata: {
    [key: string]: any;
  } | null;
}

export interface PartnerInfo {
  partnerOfferRef?: string;
  partnerName?: string;
  partnerRef: string;
  resellerAgreement?: ResellerAgreement;
}

export interface PartnerInfoJSON {
  partner_offer_ref?: string;
  partner_name?: string;
  partner_ref: string;
  reseller_agreement?: ResellerAgreementJSON;
}

export interface ResellerAgreement {
  type: string;
  files?: string[];
  documentUrns?: string[];
}

export type ResellerAgreementType =
  | 'none-selected' // no reseller agreement used
  | 'rcmp-2021-12-01' // standard aws reseller agreement
  | 'custom' // only uploaded files from ISV used
  | string;

export interface ResellerAgreementJSON {
  type: ResellerAgreementType;
  files?: string[];
  document_urns?: string[];
}

export const getUserValueByContactType = (
  contactType: string,
  usersToNotify: UsersToNotify[],
): string[] => flow(filter({ contactType }), map('value'))(usersToNotify);

export const deserializeActivity = (jsonActivity: ActivityJSON): Activity => {
  const dtKeys = map(camelCase, datetimeKeys);

  const activity = convertKeysTo(camelCase)(jsonActivity);
  const convertedDateTimes = stringToDateTime(dtKeys, activity);

  return {
    ...activity,
    ...convertedDateTimes,
  } as Activity;
};

// dateimeKeys should match JSON/API format (snake_case)
export const datetimeKeys = [
  'created_at',
  'last_modified_at',
  'sent_at',
  'accepted_at',
  'archived_at',
  'created_at',
  'viewed_offer_at',
  // including both here since key conversion needs a non-nested key
  'metadata.offer_expiration_at',
  'offer_expiration_at',
  'metadata.offer_start_at',
  'offer_start_at',
  'metadata.offer_end_at',
  'offer_end_at',
  'metadata.pricing.service_start_at',
  'service_start_at',
  'metadata.pricing.service_end_at',
  'service_end_at',
];

// Data stored in these keys are controlled by the ISV/User...
// do not modify key names
const uncontrolledObjectKeys = ['offer_metadata', 'pre_registration_details'];

export const deserialize = (
  jsonPrivateOffer: PrivateOfferJSON,
): PrivateOffer => {
  const dtKeys = map(camelCase, datetimeKeys);

  const po = convertKeysTo(camelCase, [...uncontrolledObjectKeys, ...dtKeys])(
    jsonPrivateOffer,
  );
  const activities = map(deserializeActivity)(po.activities as ActivityJSON[]);
  const convertedDateTimes = stringToDateTime(dtKeys, po);

  return {
    ...po,
    ...convertedDateTimes,
    activities,
  } as PrivateOffer;
};

export const serialize = (
  privateOffer: Partial<PrivateOffer>,
): Partial<PrivateOfferJSON> => {
  const dtKeys = map(snakeCase, datetimeKeys);

  const po: Partial<PrivateOffer> = convertKeysTo(snakeCase, [
    ...uncontrolledObjectKeys,
    ...dtKeys,
  ])(privateOffer);
  const convertedDateTimes = dateTimeToString(
    dtKeys,
    po,
  ) as Partial<PrivateOfferJSON>;
  convertedDateTimes.activities = convertedDateTimes.activities?.map(
    (a) => dateTimeToString(dtKeys, a) as ActivityJSON,
  );

  const serializedPO = convertValuesTo(emptyStringToNull, [
    'pricing.account_id',
    'pricing.billing_frequency',
  ])({ ...po, ...convertedDateTimes });

  return serializedPO as Partial<PrivateOfferJSON>;
};

export interface FileRefMap {
  contentType: string;
  fileName: string;
}

export const deserializeFiles = (files: FilesJSON): DeserializedFiles => {
  return convertKeysTo(camelCase)(files);
};

export const serializeUpload = (uploadData: object): object => {
  return convertKeysTo(snakeCase)(uploadData);
};

export interface APISuccessResponse extends APISuccessRes {
  data: PrivateOfferJSON;
}

export interface UploadSuccessResponse extends APISuccessRes {
  data: FilesJSON;
}

export interface FilePresignedUrlSuccessResponse extends APISuccessRes {
  eula_file_presigned_urls: FileUrlMap;
}

export type APIResponse = APISuccessResponse | APIErrorResponseBody;

export function isAPISuccess(res: APIResponse): res is APISuccessResponse {
  return (res as APISuccessResponse).data !== undefined;
}

export type PrivateOffersReducerState = ReducerState & {
  content: PrivateOffer[];
  status: ReducerState['status'];
  error: APIErrorResponseBody | ReducerState['error'];
  selectedOffer?: PrivateOffer;
  selectedOfferStatus?: ReducerState['status'];
  listSearchParams?: URLSearchParams;
};

export interface AzureAbsolutePrice {
  billingTerm: AzurePricingTypeAndValue;
  paymentOption: AzurePricingTypeAndValue;
  pricePerPaymentInUsd: number;
}

export interface AzurePricingTypeAndValue {
  type: AzurePricingTypeOption;
  value: number;
}

export enum AzurePricingBillingTypeKeys {
  OneMonth = 'oneMonth',
  OneYear = 'oneYear',
  TwoYears = 'twoYears',
  ThreeYears = 'threeYears',
}

export type AzurePricingBillingTypeKeysType =
  | AzurePricingBillingTypeKeys.OneMonth
  | AzurePricingBillingTypeKeys.OneYear
  | AzurePricingBillingTypeKeys.TwoYears
  | AzurePricingBillingTypeKeys.ThreeYears;

export enum AzurePricingBillingTypeText {
  OneMonth = '1 month',
  OneYear = '1 year',
  TwoYears = '2 years',
  ThreeYears = '3 years',
}

export enum AzurePricingType {
  Month = 'month',
  Year = 'year',
  Flexible = 'flexible',
}

export type AzurePricingTypeOption =
  | AzurePricingType.Month
  | AzurePricingType.Year
  | AzurePricingType.Flexible;

export enum AzureOptionSubtext {
  PerMonth = 'per month',
  OneTime = 'one-time',
  PerYear = 'per year',
}

export type AzureOptionSubtextType =
  | AzureOptionSubtext.PerMonth
  | AzureOptionSubtext.PerYear
  | AzureOptionSubtext.OneTime;

export interface AzurePricingPaymentOption {
  type: AzurePricingTypeOption;
  value: number;
}

export interface AzurePriceOptionCell extends AzureAbsolutePrice {
  subtext: AzureOptionSubtextType;
  defaultValue: number;
}

/*
NOTE: [sw] we'll be using hyphens for '1-month', '1-year', etc. to match how the
billingTerm keys are formatted on a product/listing contractDimensions
*/
export enum BillingTermValue {
  OneMonth = '1-month',
  OneYear = '1-year',
  TwoYears = '2-years',
  ThreeYears = '3-years',
  Custom = 'custom',
  FutureDated = 'future_dated',
}

export type BillingTermValueType =
  | BillingTermValue.OneMonth
  | BillingTermValue.OneYear
  | BillingTermValue.TwoYears
  | BillingTermValue.ThreeYears
  | BillingTermValue.Custom
  | BillingTermValue.FutureDated;

export const BILLING_TERM_VALUE_TYPES = [
  BillingTermValue.OneMonth,
  BillingTermValue.OneYear,
  BillingTermValue.TwoYears,
  BillingTermValue.ThreeYears,
  BillingTermValue.Custom,
  BillingTermValue.FutureDated,
];

export enum PaymentModelValue {
  PerProduct = 'per_product',
  PaymentSchedule = 'payment_schedule',
  OneTime = 'one_time',
  PerMonth = 'per_month',
  PerYear = 'per_year',
}

export type PaymentModelValueType =
  | PaymentModelValue.PerProduct
  | PaymentModelValue.PaymentSchedule
  | PaymentModelValue.OneTime
  | PaymentModelValue.PerMonth
  | PaymentModelValue.PerYear;

export const PAYMENT_MODEL_VALUE_TYPES = [
  PaymentModelValue.PerProduct,
  PaymentModelValue.PaymentSchedule,
  PaymentModelValue.OneTime,
  PaymentModelValue.PerMonth,
  PaymentModelValue.PerYear,
];

export enum DurationTypeValue {
  Month = 'month',
  Months = 'months',
  Year = 'year',
}

export type DurationTypeValueType =
  | DurationTypeValue.Month
  | DurationTypeValue.Months
  | DurationTypeValue.Year;

export const DURATION_TYPE_VALUE_TYPES = [
  DurationTypeValue.Month,
  DurationTypeValue.Months,
  DurationTypeValue.Year,
];
