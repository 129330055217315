import React from 'react';
import { Box, Card, Divider } from 'vendor/material';
import useStyles from './RightRailCard.style';
import RightRailSubSection from 'packages/cosell/src/components/RightRailSubSection/RightRailSubSection';
import {
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
  AceOpportunityOriginEnum,
} from 'packages/cosell/src/types/enums';
import RightRailButtonSection from 'packages/cosell/src/components/RightRailSubSection/RightRailButtonSection';
import RightRailContactInfo from 'packages/cosell/src/components/RightRailSubSection/RightRailContactInfoSection';
import { Link } from '@tackle-io/platform-ui';
import { AwsOpportunityTeamMember } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import {
  CLOUD_CONTACTS_TITLE,
  getRightRailContentConfig,
  NO_CLOUD_CONTACT_GUIDANCE_TEXT,
  PROSPECT_REMINDER_GUIDANCE_TEXT,
  RightRailContentConfig,
  shouldRenderActionButtonSection,
  shouldRenderCloudContact,
} from './helper';
import { CalendarClockIcon } from 'packages/cosell/assets';
import Button from 'packages/cosell/src/components/Button/Button';
import { useIsSandboxEnvironment } from '../../../../../../../hooks';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { useOpportunity } from '../../../UnifiedOpportunityForm/providers/OpportunityProvider';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';

const PARTNER_CENTER_BASE_URL =
  'https://partnercentral.awspartner.com/partnercentral2/s/editopportunity';

interface RightRailCardProps {
  status: AceOpportunityReviewStatusEnum;
  reviewStatusReason: string;
  stage: AceOpportunityStageEnum;
  origin: string;
  awsOpportunityTeam: AwsOpportunityTeamMember[];
}

const RightRailCard: React.FC<RightRailCardProps> = ({
  status,
  reviewStatusReason,
  stage,
  origin,
  awsOpportunityTeam,
}) => {
  const classes = useStyles();
  const isSandboxEnvironment = useIsSandboxEnvironment();
  const { opportunityId } = useOpportunity();
  const { aceOpportunityQuery } = useAceOpportunity({ opportunityId });
  const hasPendingRequest =
    !!aceOpportunityQuery?.data?.metadata?.pendingRequestIds?.length;

  const renderActionButtonSection = shouldRenderActionButtonSection(
    status,
    stage,
    isSandboxEnvironment,
  );
  const renderCloudContact = shouldRenderCloudContact(status);
  const renderDaysReminder =
    status === AceOpportunityReviewStatusEnum.APPROVED &&
    stage === AceOpportunityStageEnum.PROSPECT &&
    !hasPendingRequest;

  const getCloudContactConfig = (
    awsOpportunityTeam: AwsOpportunityTeamMember[],
  ): RightRailContentConfig => {
    if (
      !awsOpportunityTeam ||
      awsOpportunityTeam.length === 0 ||
      !renderCloudContact
    ) {
      return {
        title: CLOUD_CONTACTS_TITLE,
        guidanceText: NO_CLOUD_CONTACT_GUIDANCE_TEXT,
      };
    }
    const contactInfo = awsOpportunityTeam.map((teamMember, i) => (
      <RightRailContactInfo
        businessTitle={teamMember.businessTitle}
        name={
          teamMember.firstName &&
          teamMember.lastName &&
          `${teamMember.firstName} ${teamMember.lastName}`
        }
        email={teamMember.email}
        key={i}
      />
    ));
    return {
      title: CLOUD_CONTACTS_TITLE,
      guidanceText: contactInfo,
    };
  };

  const rightRailContents = getRightRailContentConfig(
    status,
    stage,
    reviewStatusReason,
    isSandboxEnvironment,
    hasPendingRequest,
  );
  const cloudContactConfig = getCloudContactConfig(awsOpportunityTeam);

  return (
    <Card variant="outlined" className={classes.rightRailCard}>
      {rightRailContents && (
        <RightRailSubSection
          title={rightRailContents?.title}
          subTitle={rightRailContents?.subTitle}
          guidanceText={rightRailContents?.guidanceText}
          icon={rightRailContents?.icon}
          subIcon={rightRailContents?.subIcon}
        />
      )}
      {renderDaysReminder && (
        <RightRailSubSection
          subTitle="28 days to avoid cloud auto close" //TODO: days should be dynamic
          subIcon={<CalendarClockIcon />}
          guidanceText={PROSPECT_REMINDER_GUIDANCE_TEXT}
        />
      )}

      {renderActionButtonSection && (
        <Box mt={2}>
          <RightRailButtonSection
            status={status}
            stage={stage}
            hasPendingRequest={hasPendingRequest}
          />
        </Box>
      )}

      {status !== AceOpportunityReviewStatusEnum.PENDING_SUBMISSION &&
        !hasPendingRequest && (
          <>
            <Box mt={2} mb={1}>
              <Divider />
            </Box>
            <RightRailSubSection
              title="View on cloud portal"
              guidanceText={
                <Link
                  to={`${PARTNER_CENTER_BASE_URL}?id=0068W00000msrTLQAY`}
                  external
                >
                  {/* TODO: They opp cloud id should be dynamic, but currently we don't have 006 cloud id in the api */}
                  {`${PARTNER_CENTER_BASE_URL}?id=0068W00000msrTLQAY`}
                </Link>
              }
            />
          </>
        )}

      {origin === AceOpportunityOriginEnum.AWS_REFERRAL && (
        <div>
          <Box mt={2} mb={1}>
            <Divider />
          </Box>

          {/*TODO: adding api invitation link will be in a follow up PR*/}
          {/*this link only render when the approved opportunity originated from an AWS invitation*/}
          {/*Question: what does it mean? It means for only for partner originated opportunity NOT for invitations?*/}
          <RightRailSubSection
            title="Related invitation"
            guidanceText={
              <Link
                to="https://example.com/invitation-ww5skj2oejvbi"
                external
                showExternalIcon
              >
                invitation-ww5skj2oejvbi
              </Link>
            }
          />
        </div>
      )}

      <Box mt={2} mb={1}>
        <Divider />
      </Box>

      {/*TODO: this related offer link will be in a follow up PR  */}
      {/* <RightRailSubSection
        title="Related offer"
        guidanceText={
          <Link
            to="https://example.com/invitation-ww5skj2oejvbi"
            external
            showExternalIcon
          >
            invitation-ww5skj2oejvbi
          </Link>
        }
      />
      <Box mt={2} mb={2}>
        <Divider />
      </Box> */}

      {/* cloud contact conditional rendering
      if no cloud contact render RightRailSubSection with guidance text
      if there are contact info, render the list of the contact */}
      {/* it should be the awsOpportunityTeam in the api */}

      <RightRailSubSection
        title={cloudContactConfig.title}
        guidanceText={cloudContactConfig.guidanceText}
      />

      {/* TODO: check what are the conditions to render this button */}
      {renderCloudContact && awsOpportunityTeam?.length > 0 && (
        <Box mt={2}>
          <Button
            data-id={coSellDetailsPageDataId.CONTACT_CLOUD_PARTNER}
            variant="outlined"
            appearance="primary"
            fullWidth
          >
            Contact cloud partner
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default RightRailCard;
