import {
  CurrencyFormField,
  DateFormField,
  SingleSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';
import { AceOpportunityReviewStatusEnum } from 'packages/cosell/src/types/enums';
import { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { getCoSellStageOptions, isFieldEditable } from './helpers';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';

// TODO: Should update field to be SingleSelectFormField when API is ready
export const MarketplaceOfferId = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <TextFormField
    label="Marketplace offer ID"
    name="marketplaceOfferId"
    dataId={coSellAceOpportunityFormFieldsDataId.MARKETPLACE_OFFER_ID}
    disabled={
      !isFieldEditable({
        name: 'parentOppId',
        status,
      })
    }
  />
);

export const ReasonForClosing = ({
  awsEnumListMap,
  status,
}: {
  awsEnumListMap: AwsEnumListResponse;
  status: AceOpportunityReviewStatusEnum;
}) => (
  <SingleSelectFormField
    fieldId="closedLostReason"
    filterSelectedOptions={true}
    label="Reason for closing"
    data-id={coSellAceOpportunityFormFieldsDataId.REASON_FOR_CLOSING}
    options={awsEnumListMap?.['lifeCycle.closedLostReason']}
    required
    disabled={
      !isFieldEditable({
        name: 'closedLostReason',
        status,
      })
    }
  />
);

export const ProcurementType = ({
  awsEnumListMap,
  status,
}: {
  awsEnumListMap: AwsEnumListResponse;
  status: AceOpportunityReviewStatusEnum;
}) => (
  <SingleSelectFormField
    fieldId="deliveryModel"
    filterSelectedOptions={true}
    label="Procurement type"
    data-id={coSellAceOpportunityFormFieldsDataId.PROCUREMENT_TYPE}
    options={awsEnumListMap?.['softwareRevenue.deliveryModel']}
    helperText="Indicate one or more of the most applicable deployment or consumption model for your solution or service."
    required
    disabled={
      !isFieldEditable({
        name: 'deliveryModel',
        status,
      })
    }
  />
);

export const CustomerSoftwareValue = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <CurrencyFormField
    name="customerSoftwareValue"
    label="Customer software value"
    dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_SOFTWARE_VALUE}
    required
    disabled={
      !isFieldEditable({
        name: 'customerSoftwareValue',
        status,
      })
    }
  />
);

export const CurrencyCode = ({
  awsEnumListMap,
}: {
  awsEnumListMap: AwsEnumListResponse;
}) => (
  <SingleSelectFormField
    fieldId="currencyCode"
    filterSelectedOptions={true}
    label="Currency"
    data-id={coSellAceOpportunityFormFieldsDataId.CURRENCY_CODE}
    options={getCoSellStageOptions(
      awsEnumListMap?.['softwareRevenue.value.currencyCode'],
    )}
    required
  />
);

export const EffectiveDate = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <DateFormField
    name="effectiveDate"
    label="Contract start date"
    dataId={coSellAceOpportunityFormFieldsDataId.CONTRACT_START_DATE}
    required
    disabled={
      !isFieldEditable({
        name: 'effectiveDate',
        status,
      })
    }
  />
);

export const ExpirationDate = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <DateFormField
    name="expirationDate"
    label="Contract end date"
    dataId={coSellAceOpportunityFormFieldsDataId.CONTRACT_END_DATE}
    required
    disabled={
      !isFieldEditable({
        name: 'expirationDate',
        status,
      })
    }
  />
);
