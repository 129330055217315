import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'vendor/auth0';

import { useReturnTo } from '../hooks/useReturnTo';

export function Root() {
  const { isLoading } = useAuth();
  const [returnTo, setReturnTo] = useReturnTo();
  const [redirectPath, setRedirectPath] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (redirectPath) return;

    if (returnTo) {
      setRedirectPath(returnTo);
      setReturnTo(null);
    } else {
      setRedirectPath('/dashboard');
    }
  }, [redirectPath, returnTo, setReturnTo]);

  if (isLoading) {
    return null;
  }

  return <Redirect to={{ ...location, pathname: redirectPath || '/' }} />;
}
