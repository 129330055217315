import { useMemo } from 'react';
import useStyles from './CoSellTabs.styles';
import { Loader, Tabs } from '@tackle-io/platform-ui';
import { CoSellSearch } from '../CoSellSearch/CoSellSearch';
import { CoSellOpportunitiesTable } from '../CoSellOpportunitiesTable/CoSellOpportunitiesTable';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getUrlParamsPath,
  Operation,
  TAB_KEY,
} from '../../helpers/urlParamsHelper';
import { CoSellInvitationsTable } from '../CoSellInvitationsTable/CoSellInvitationsTable';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { useCanUseAwsFeatures } from 'packages/cosell/src/hooks/useCanUseAwsFeatures/useCanUseAwsFeatures';

const OpportunitiesTab = () => {
  const classes = useStyles();

  return (
    <div className={classes.tabsContent}>
      <CoSellSearch />
      <CoSellOpportunitiesTable />
    </div>
  );
};
const InvitationsTab = () => {
  const classes = useStyles();

  return (
    <div className={classes.tabsContent}>
      <CoSellInvitationsTable />
    </div>
  );
};

const tabOptions = {
  invitations: {
    id: 'invitations',
    label: 'Invitations',
    content: InvitationsTab,
  },
  opportunities: {
    id: 'opportunities',
    label: 'Opportunities',
    content: OpportunitiesTab,
  },
};

const DEFAULT_TAB_ID = tabOptions.opportunities.id;

export const CoSellTabs = () => {
  const { loading: featureAccessLoading, canUse } = useCanUseAwsFeatures();

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get(TAB_KEY);

  // Only add tabs that user has access to
  const tabs = useMemo(() => {
    /** initialize tabSet with tabs not gated by feature flag */
    const tabsSet = new Set([tabOptions.opportunities]);

    if (canUse.invitations.view) {
      tabsSet.add(tabOptions.invitations);
    }

    return Array.from(tabsSet);
  }, [canUse.invitations.view]);

  const handleTabClick = (tabIndex: number) => {
    const filter = {
      operation: Operation.TAB,
      value: DEFAULT_TAB_ID,
    };

    const selectedTab = tabs[tabIndex];
    if (selectedTab) {
      filter.value = selectedTab.id;
    }

    const correctedPath = getUrlParamsPath({
      filter,
      location,
    });

    history.push(correctedPath);
  };

  const activeTabIndex = tabs.findIndex((tab) =>
    activeTab ? tab.id === activeTab : tab.id === DEFAULT_TAB_ID,
  );

  if (featureAccessLoading) return <Loader />;

  return (
    <div data-id={coSellLandingPageDataId.TABS}>
      <Tabs
        key={activeTab}
        tabs={tabs}
        onChange={(_e, tabIndex: number) => handleTabClick(tabIndex)}
        value={activeTabIndex}
      />
    </div>
  );
};
