import { makeStyles } from 'vendor/material';

export const useCanvasTackleIntegrationStyles = makeStyles((theme) => ({
  integrationWindow: {
    padding: '24px',
  },
  integrationBannerContainer: {
    marginBottom: '12px',
  },
  integrationIcon: {
    marginTop: '24px',
    maxWidth: '222px',
    display: 'block',
    margin: '0px auto',
  },
  button: {
    marginTop: '24px',
    display: 'block',
    margin: '0px auto',
    minWidth: '340px',
  },
}));
