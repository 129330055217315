import { InputBase, makeStyles, withStyles } from 'vendor/material';

export const TextInputBase = withStyles((theme) => ({
  input: {
    padding: '0 12px',
    paddingRight: theme.spacing(3.75),
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.NEUTRAL800,
    fontWeight: 700,
    border: `1px solid ${theme.palette.NEUTRAL050}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: '#fff',
    fontFamily: theme.typography.fontFamily,
    '&:hover': {
      border: `1px solid ${theme.palette.NEUTRAL100}`,
    },
    'select&, select&:focus': {
      borderRadius: 4,
    },
    '&:focus': {
      border: `2px solid ${theme.palette.BLUE400}`,
    },
    '&::placeholder': {
      fontWeight: 400,
      color: theme.palette.NEUTRAL300,
      opacity: 1,
    },
  },
}))(InputBase);

export const useTextFieldStyles = makeStyles((theme) => ({
  inputBase: {
    width: '100%',
    padding: 0,
    '$inputContainer:hover & input': {
      border: `1px solid ${theme.palette.NEUTRAL100}`,
    },
    '$inputContainer:hover &.Mui-focused input': {
      border: `2px solid ${theme.palette.BLUE500}`,
    },
    '$inputContainer:hover &.Mui-focused input:hover': {
      border: `2px solid ${theme.palette.BLUE500}`,
    },
  },
  inputLabel: {
    position: 'static',
    marginBottom: 4,
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.NEUTRAL300,
    transform: 'translate(0, 1.5px) scale(1)',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium as number,
    '&.Mui-focused': {
      color: theme.palette.BLUE400,
    },
    '&.Mui-disabled': {
      color: theme.palette.NEUTRAL300,
    },
  },
  inputLabelFocused: {
    color: theme.palette.BLUE400,
  },
  secondaryLabel: {
    color: theme.palette.NEUTRAL300,
    fontWeight: theme.typography.fontWeightRegular as number,
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
    '&.input-type-number': {
      '& input': {
        paddingRight: 12,
      },
    },
    "& [inputmode='numeric']": {
      padding: '0 12px',
      width: '100%',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid #DADCE0',
      borderRadius: 4,
      boxSizing: 'border-box',
      height: 40,
      backgroundColor: '#fff',
      fontFamily: theme.typography.fontFamily,
      '&:focus': {
        borderColor: theme.palette.BLUE400,
      },
      '&:disabled': {
        color: theme.palette.NEUTRAL300,
        backgroundColor: theme.palette.NEUTRAL030,
      },
      '&:read-only': {
        color: theme.palette.NEUTRAL300,
        backgroundColor: theme.palette.NEUTRAL030,
      },
    },
    '& textarea[rows]': {
      height: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  inputContainerError: {
    "& [inputmode='numeric']": {
      borderWidth: '2px',
      borderColor: theme.palette.RED400,
    },
  },
  inputDisabled: {
    '& input, & textarea': {
      color: theme.palette.NEUTRAL300,
      backgroundColor: theme.palette.NEUTRAL030,
    },
    '& input:hover, & textarea:hover, & select:hover': {
      borderColor: theme.palette.NEUTRAL050,
    },
    'select&': {
      color: theme.palette.NEUTRAL300,
      backgroundColor: theme.palette.NEUTRAL030,
    },
  },
  inputError: {
    '& input, & textarea, & select': {
      borderWidth: '2px',
      borderColor: theme.palette.RED400,
    },
    '& input:hover, & textarea:hover, & select:hover': {
      borderWidth: '2px',
      borderColor: theme.palette.RED500,
    },
    '& input:focus, & textarea:focus, & select:focus': {
      borderWidth: '2px',
      borderColor: theme.palette.BLUE400,
    },
  },
  inputAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputGroupAppend: {
    display: 'flex',
    marginLeft: -1,
    '& button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  inputBtn: {
    position: 'relative',
    zIndex: 2,
    cursor: 'pointer',
    color: '#6c757d',
    borderColor: '#DADCE0',
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    userSelect: 'none',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    fontSize: '1rem',
    lineHeight: theme.typography.pxToRem(1.5),
    borderRadius: '.25rem',
    maxHeight: 41,
    padding: '9px 10px',
  },
  withAppend: {
    '& input, & textarea': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  withIconLeft: {
    '& input, & textarea': {
      paddingLeft: 35,
    },
  },
  withIconRight: {
    '& input, & textarea': {
      paddingRight: 60,
    },
  },
  iconLeft: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    top: 'calc(50% - 12px)',
    left: 8,
  },
  iconRight: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    top: 'calc(50% - 8px)',
    right: 12,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.NEUTRAL700,
    '& button': {
      fontSize: theme.typography.pxToRem(16),
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
    },
  },
  hexIcon: {
    position: 'absolute',
    zIndex: 1,
    top: 'calc(50% - 8px)',
    left: 12,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.NEUTRAL300,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helperTextContainer: {
    flex: '1 1 auto',
    '& p': {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.NEUTRAL300,
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      '&.Mui-disabled': {
        color: theme.palette.NEUTRAL300,
      },
    },
  },
  helperTextDisabled: {
    '& p': {
      color: theme.palette.NEUTRAL300,
    },
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.RED400,
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      marginRight: 0,
    },
  },
  smallSize: {
    height: 32,
    '& input, & textarea': {
      height: 32,
    },
  },
  checkbox: {
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(20),
  },
}));
