import { Box, Tab, Tabs } from 'vendor/material';
import useStyles from './ContractTabs.style';
import { useState } from 'react';
import DetailsTab from './components/DetailsTab/DetailsTab';
import PaymentScheduleTab from './components/PaymentScheduleTab/PaymentScheduleTab';
import PaymentsTab from './components/PaymentsTab/PaymentsTab';
import UsageTab from './components/UsageTab/UsageTab';
import { useSingleContractData } from 'pages/Contracts/pages/Details/providers/SingleContractDataProvider';

interface PanelSelectorProps {
  children?: React.ReactNode;
  index: number;
  selectedIndex: number;
}
const PanelSelector = (props: PanelSelectorProps) => {
  const { children, selectedIndex, index } = props;

  if (selectedIndex !== index) {
    return null;
  }
  return <>{children}</>;
};

const ContractTabs: React.FC = () => {
  const classes = useStyles();
  const [contractDetailTabIndex, setContractDetailTabValue] = useState(0);
  const { contract } = useSingleContractData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setContractDetailTabValue(newValue);
  };

  return (
    <Box className={classes.tabsContainer}>
      <Box>
        <Tabs
          value={contractDetailTabIndex}
          TabIndicatorProps={{ className: classes.tabIndicator }}
          onChange={handleChange}
        >
          <Tab label="Details" className={classes.tabLabel} />
          <Tab label="Payment schedule" className={classes.tabLabel} />
          <Tab label="Payments" className={classes.tabLabel} />
          <Tab label="Usage" className={classes.tabLabel} />
        </Tabs>
      </Box>

      <PanelSelector selectedIndex={contractDetailTabIndex} index={0}>
        <DetailsTab contract={contract} />
      </PanelSelector>
      <PanelSelector selectedIndex={contractDetailTabIndex} index={1}>
        <PaymentScheduleTab contract={contract} />
      </PanelSelector>
      <PanelSelector selectedIndex={contractDetailTabIndex} index={2}>
        <PaymentsTab contract={contract} />
      </PanelSelector>
      <PanelSelector selectedIndex={contractDetailTabIndex} index={3}>
        <UsageTab contract={contract} />
      </PanelSelector>
    </Box>
  );
};

export default ContractTabs;
