import { Box } from 'vendor/material';
import useStyles from './PaymentsTab.style';
import { useEffect, useState } from 'react';
import { ampli } from 'utils/analytics/ampli';

interface PaymentsTabProps {
  contract: any;
}

const PaymentsTab: React.FC<PaymentsTabProps> = ({ contract }) => {
  const classes = useStyles();
  const [eventSent, setEventSent] = useState(false);

  useEffect(() => {
    if (contract && !eventSent) {
      ampli.contractDetailTabViewed({
        marketplace: contract?.cloudMarketplace,
        contract_status: contract?.contractStatus,
        offer_origination: contract?.offerType,
        pricing_model: contract?.pricingModel,
        tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
        contract_detail_tab: 'Payments',
      });
      setEventSent(true);
    }
  }, [contract, eventSent]);

  if (!contract) {
    return <div>Loading...</div>;
  }

  return (
    <Box className={classes.container}>Payments tab is under construction</Box>
  );
};

export default PaymentsTab;
