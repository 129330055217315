import { UsageDimension } from '../../../stores/privateOffers/typings';
import { toFinite } from 'lodash/fp';
import { FormMetadataUsageDimension } from '../utils';

type UsageDimensionTypes = UsageDimension | FormMetadataUsageDimension;

export const toFormUsageDimension = ({
  sku,
  description,
  price = null,
  discountPercentage = null,
}: UsageDimensionTypes): FormMetadataUsageDimension => ({
  sku,
  description,
  price: price ? toFinite(price) : undefined,
  discountPercentage: discountPercentage
    ? toFinite(discountPercentage)
    : undefined,
});
