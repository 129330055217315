import { useMemo } from 'react';
import AceOpportunityForm from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityForm';
import { DisplayCloudType } from '../../types/enums';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { OpportunityProvider } from './providers/OpportunityProvider';
import { useCurrentUserVendorQuery } from 'generated/graphql';
import { UnifiedOpportunityView } from '../../utilities/constants';
import UnifiedOpportunityDetails from '../UnifiedOpportunityDetails';

interface RenderFormProps {
  cloud?: string;
  opportunityId?: string;
  view?: UnifiedOpportunityView;
}

const renderForm = ({ cloud, opportunityId, view }: RenderFormProps) => {
  if (cloud === DisplayCloudType.AWS) {
    switch (view) {
      case UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM:
        return <AceOpportunityForm />;
      case UnifiedOpportunityView.EDIT_CO_SELL_FORM:
        return <AceOpportunityForm opportunityId={opportunityId} />;
      case UnifiedOpportunityView.CO_SELL_DETAILS:
        return <UnifiedOpportunityDetails />;
      default:
        return <div>Not found page</div>;
    }
  }
};

const UnifiedOpportunityForm = ({
  view,
  match,
}: {
  view: UnifiedOpportunityView;
  match: { params: { [key: string]: string }; url: string };
}) => {
  const cloud = useMemo(
    () => match.params.cloud.split('?')[0],
    [match.params.cloud],
  );
  const opportunityId = useMemo(
    () => match.params.opportunityId?.split('?')[0],
    [match.params.opportunityId],
  );

  return (
    <UnifiedOpportunityFormContainer opportunityId={opportunityId}>
      {renderForm({ cloud, opportunityId, view })}
    </UnifiedOpportunityFormContainer>
  );
};

interface UnifiedOpportunityFormContainerProps {
  vendorIdFromCanvas?: string;
  tokenFromCanvas?: string;
  opportunityId?: string;
  children?: React.ReactElement;
}
export const UnifiedOpportunityFormContainer = ({
  vendorIdFromCanvas,
  opportunityId,
  children,
}: UnifiedOpportunityFormContainerProps) => {
  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: !!vendorIdFromCanvas,
  });

  if (!currentUserData?.currentUser?.vendor?.id) {
    return <div>Not Authenticated</div>;
  }
  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <OpportunityProvider
        opportunityId={opportunityId}
        vendorId={currentUserData?.currentUser?.vendor?.id}
      >
        {children}
      </OpportunityProvider>
    </ErrorBoundary>
  );
};
export default UnifiedOpportunityForm;
