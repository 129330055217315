import {
  compact,
  cond,
  curry,
  defaultTo,
  filter,
  find,
  flow,
  get,
  identity,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isUndefined,
  map,
  partial,
  pick,
  reject,
  replace,
  set,
  sortBy,
  stubTrue,
  sum,
  toFinite,
  toString,
  trim,
  unionBy,
  unset,
} from 'lodash/fp';

import { Cloud, RecursivePartial } from 'stores/typings';
import { DateTime } from 'luxon';

import {
  AzureAbsolutePrice,
  AzureOfferPlan,
  AzureOfferPlanMarketplacePricingV1,
  BillingTermValue,
  BillingTermValueType,
  Dimension,
  DurationTypeValue,
  DurationTypeValueType,
  MetadataPricing,
  PaymentModelValue,
  PaymentModelValueType,
  PrivateOffer,
  RedHatPricing,
  Schedule,
  ScheduleInvoiceDateType,
  Unit,
  UsageDimension,
} from 'stores/privateOffers/typings';

import {
  AwsProduct,
  GcpProduct,
  isAwsAmiHourlyAnnualProduct,
  isAwsProduct,
  isGcpProduct,
  isRedhatProduct,
  Product,
} from 'stores/products/typings';

import {
  CloudToPricingVersion,
  getPricingByCloudAndListingType,
  Pricing,
} from 'utils/pricingTypes';

import { Cloud as CloudEnum, CloudType } from 'utils/cloudTypes';

import { isAmiHourlyAnnual, ListingTypeType } from 'utils/listingTypes';

import { EditionCharge } from 'stores/products/typings/RedhatProduct';
import { createDurationValue } from 'utils/pricingDurationTypes';
import {
  convertLocalJSDateToMidnightISOString,
  FormUsageDimension,
  getMonthsDifferenceFromJSDates,
} from 'pages/PrivateOffers/utils/formatFormData';
import { billingTermOptionsByCloud } from '../../pages/PrivateOffers/components/FieldsPricingConfig/constants';
import { getAbsolutePriceBillingTerm } from '../../pages/PrivateOffers/components/AzurePlanCustomization/utils/azureBillingTermUtils';
import { getAbsolutePricePaymentOption } from '../../pages/PrivateOffers/components/AzurePlanCustomization/utils/azurePaymentModelUtils';
import { CurrencyCode } from '../../utils/currency';
import { getDefaultGCPUsageDimensions } from './UsageDimensionFields/gcp/gcpUsageDimensionUtils';
import { toFormUsageDimension } from './UsageDimensionFields/usageDimensionUtils';

export interface FormMetadataDimension {
  name: string | undefined;
  price: number | undefined;
  quantity?: string | number | undefined;
  absolutePrices?: AzureAbsolutePrice[] | undefined;
  description?: string | undefined;
  apiName?: string | undefined;
}

export interface FormMetadataUnit {
  unit: string | undefined;
  hourlyPrice: number | undefined;
  durationPrice: number | undefined;
}

export interface FormMetadataUsageDimension {
  sku: string;
  price: number | null;
  description: string;
  discountPercentage: number | null;
}

export interface FormMetadataPricingDetails {
  paymentModel?: PaymentModelValueType;
  billingTerm?: BillingTermValueType;
  durationType?: DurationTypeValueType;
  durationValue?: number;
  dimensions: Array<FormMetadataDimension>;
  units: Array<FormMetadataUnit>;
  usageDimensions: Array<FormMetadataUsageDimension>;
  duration: string | number;
  showOnEmail: boolean;
  marketplaceFee: number;
  schedule: Schedule[];
  version: string;
  serviceStartAt?: string;
  serviceEndAt?: string;
  allowAutoRenew?: boolean;
  currencyCode?: string;
}

interface FormRedhatPricingDetailsEditionCharge {
  id: string;
  quantity: number;
  unitPrice: string;
  include: boolean;
}

interface FormRedHatPricingDetails {
  marketplaceFee: number;
  editionId: string;
  subscriptionTerm: string;
  billingFrequency: string;
  editionCharges: FormRedhatPricingDetailsEditionCharge[];
}

export type FormPricingDetails =
  | FormMetadataPricingDetails
  | FormRedHatPricingDetails;

export type PrivateOfferPricingDetails = RedHatPricing | MetadataPricing;

function isFormRedHatPricingDetails(
  pricing: FormPricingDetails,
): pricing is FormRedHatPricingDetails {
  return 'editionId' in (pricing as FormRedHatPricingDetails);
}

const formPath = 'pricing';
const editionIdFormPath = `${formPath}.editionId`;
const billingFrequencyFormPath = `${formPath}.billingFrequency`;
const subscriptionTermFormPath = `${formPath}.subscriptionTerm`;
const editionChargesFormPath = `${formPath}.editionCharges`;
export const paymentModelFormPath = `${formPath}.paymentModel`;
export const scheduleFormPath = `${formPath}.schedule`;
export const billingTermFormPath = `${formPath}.billingTerm`;
export const durationValueFormPath = `${formPath}.durationValue`;
export const serviceStartAtFormPath = `${formPath}.serviceStartAt`;
export const serviceEndAtFormPath = `${formPath}.serviceEndAt`;
export const currencyCodeFormPath = `${formPath}.currencyCode`;
export const usageDimensionsFormPath = `${formPath}.usageDimensions`;

const metadataPrivateOfferPath = 'metadata.pricing';
const redHatPrivateOfferPath = 'pricing';

const getProductUsageFees = (product: Product): UsageDimension[] =>
  get('pricing.usageFees', product) || [];
const matchesProductUsageFees = curry(
  (product: Product, offerUsageDimensions: UsageDimension[]) =>
    isEqual(
      new Set(map(pick(['sku', 'description']), getProductUsageFees(product))),
      new Set(map(pick(['sku', 'description']), offerUsageDimensions)),
    ),
);

const getProductContractDimensions = (product?: AwsProduct): Dimension[] => {
  const contractDimensions = product?.pricing?.contractDimensions ?? [];

  return contractDimensions.map((cd) => ({
    name: cd.name,
    apiName: cd.sku,
    price: undefined,
  }));
};

export interface PricingFieldsConditionals {
  isAzureLTSPricing: boolean; // todo: replace usage with pricingVersionOverride
  showFDAPricing: boolean;
  pricingVersionOverride: CloudToPricingVersion;
}

const getFirstBillingTermForCloud = (
  cloud?: Cloud,
): BillingTermValue | null => {
  const billingTermOptions = billingTermOptionsByCloud[cloud] ?? [];
  const firstBillingTermOption = billingTermOptions.at(0);
  return firstBillingTermOption?.value;
};

const getFormDefaultBillingTerm = <FormDataType>(
  formData: FormDataType & { pricing?: Partial<FormPricingDetails> },
  pricingVersion: Pricing,
  cloud?: Cloud,
) => {
  const formDataBillingTerm = get([formPath, 'billingTerm'])(formData);
  return (
    formDataBillingTerm ??
    (pricingVersion === Pricing.AzureMarketplacePricingV1
      ? undefined
      : getFirstBillingTermForCloud(cloud) ?? BillingTermValue.Custom)
  );
};

const defaultAzureMarketplaceV1Dimensions = [
  {
    name: null,
    absolutePrices: [
      {
        billingTerm: { type: null, value: null },
        paymentOption: { type: null, value: null },
        pricePerPaymentInUsd: null,
      },
    ],
  },
];

const getDefaultCurrencyCode = <FormDataType>(
  usageDimensions: FormUsageDimension[],
  data: FormDataType & { pricing?: Partial<FormPricingDetails> },
) => {
  return flow([
    cond([
      [
        () => usageDimensions?.length > 0,
        () => CurrencyCode.UnitedStatesDollar,
      ],
      [stubTrue, get([formPath, 'currencyCode'])],
    ]),
    defaultTo(CurrencyCode.UnitedStatesDollar),
  ])(data);
};

const getDefaultAWSUsageDimensions = <FormDataType>(
  product: AwsProduct,
  data: FormDataType & { pricing?: Partial<FormPricingDetails> },
): FormMetadataUsageDimension[] => {
  return flow([
    get([formPath, 'usageDimensions']),
    cond([
      [matchesProductUsageFees(product), identity],
      [stubTrue, () => getProductUsageFees(product)],
    ]),
    map(toFormUsageDimension),
  ])(data);
};

export default function addFormDefaults<FormDataType = Record<string, any>>(
  data: FormDataType & { pricing?: Partial<FormPricingDetails> },
  product?: Product,
  pricingFieldsConditionals?: PricingFieldsConditionals,
  privateOfferProductRef?: PrivateOffer['productRef'],
): FormDataType & { pricing?: Partial<FormPricingDetails> } {
  if (isUndefined(product)) return unset('pricing')(data);
  const { isAzureLTSPricing, showFDAPricing, pricingVersionOverride } =
    pricingFieldsConditionals;

  const marketplaceFee = toFinite(data?.pricing?.marketplaceFee ?? 0);

  if (isRedhatProduct(product)) {
    const editionId = flow([
      get(editionIdFormPath),
      defaultTo(get('pricing.editions[0].id', product)),
    ])(data);

    const subscriptionTerm = flow([
      get(subscriptionTermFormPath),
      defaultTo('1'),
    ])(data);

    const billingFrequency = flow([
      get(billingFrequencyFormPath),
      defaultTo(get('pricing.editions[0].billingFrequency[0]', product)),
      defaultTo('monthly'),
    ])(data);

    const defaultEditionCharges = flow([
      get('pricing.editions'),
      find({ id: editionId }),
      get('editionCharges'),
      defaultTo(get('pricing.editions[0].editionCharges', product)),
      sortBy(['chargeType', 'vendorChargeid']),
      map((ec: EditionCharge) => ({
        id: ec.vendorChargeid,
        quantity: 1,
        unitPrice: ec.price,
        include: ec.required,
      })),
    ])(product);

    const editionCharges = flow([
      get(editionChargesFormPath),
      defaultTo(defaultEditionCharges),
      unionBy('id', partial.placeholder, defaultEditionCharges),
    ])(data);

    const pricing: FormRedHatPricingDetails = {
      marketplaceFee,
      editionId,
      subscriptionTerm,
      billingFrequency,
      editionCharges,
    };

    return set(formPath, pricing, data);
  }

  const rawDuration = get([formPath, 'duration'], data);

  const duration =
    isGcpProduct(product) || isAwsProduct(product)
      ? parseDuration(rawDuration, product?.listingType as ListingTypeType)
      : rawDuration;

  const version =
    pricingVersionOverride[product?.cloud] ||
    getPricingByCloudAndListingType(
      product?.cloud as CloudType,
      product?.listingType as ListingTypeType,
      isAzureLTSPricing,
    );

  const isAzureMarketplacePricingV1 =
    version === Pricing.AzureMarketplacePricingV1;

  const defaultDimensions = isAzureMarketplacePricingV1
    ? defaultAzureMarketplaceV1Dimensions
    : [{ name: undefined, price: undefined }];

  const dimensions = flow([
    get([formPath, 'dimensions']),
    cond([
      [
        (formDimensions) =>
          isEmpty(formDimensions) && product.cloud === CloudEnum.Aws,
        () => getProductContractDimensions(product as AwsProduct),
      ],
      [stubTrue, identity],
    ]),
    map(toFormDimensionValue(version)),
    cond([
      [isEmpty, () => defaultDimensions],
      [stubTrue, identity],
    ]),
  ])(data);

  const usageDimensions = isAwsProduct(product)
    ? getDefaultAWSUsageDimensions(product as AwsProduct, data)
    : isGcpProduct(product)
    ? getDefaultGCPUsageDimensions(product as GcpProduct, data)
    : undefined;

  const defaultUnits = [
    { unit: undefined, hourlyPrice: undefined, durationPrice: undefined },
  ];
  const unitsFromData = flow([get([formPath, 'units']), map(toFormUnit)])(data);

  const units = unitsFromData.length > 0 ? unitsFromData : defaultUnits;

  const showOnEmail = flow([
    get([formPath, 'showOnEmail']),
    (v: any): boolean => Boolean(v),
  ])(data);

  const paymentModel =
    get([formPath, 'paymentModel'])(data) ??
    (isAzureMarketplacePricingV1
      ? undefined
      : PaymentModelValue.PaymentSchedule);

  const isAzureOfferUsingSchedules =
    isAzureMarketplacePricingV1 &&
    paymentModel === PaymentModelValue.PaymentSchedule;

  let schedule = get([formPath, 'schedule'], data);
  const scheduleUsed =
    product?.cloud === CloudEnum.Aws ||
    version === Pricing.GcpMarketplacePricingV1 ||
    isAzureOfferUsingSchedules;

  if (!schedule && scheduleUsed) {
    schedule = [{ invoiceDate: undefined, invoiceAmount: undefined }];
  }

  const billingTerm = getFormDefaultBillingTerm(data, version, product?.cloud);
  const currencyCode = getDefaultCurrencyCode(usageDimensions, data);

  const pricing: FormMetadataPricingDetails = {
    dimensions,
    units: isAwsAmiHourlyAnnualProduct(product) ? units : [],
    usageDimensions,
    duration,
    showOnEmail,
    marketplaceFee,
    schedule,
    version,
    ...(isAwsProduct(product) &&
      showFDAPricing && {
        billingTerm,
        serviceStartAt: undefined,
        serviceEndAt: undefined,
      }),
    ...(isAwsProduct(product) &&
      !isAmiHourlyAnnual(product?.listingType) && {
        billingTerm,
        paymentModel,
        currencyCode,
      }),
    ...(version === Pricing.GcpMarketplacePricingV1 && {
      billingTerm,
      serviceStartAt: undefined,
      serviceEndAt: undefined,
      paymentModel: PaymentModelValue.PaymentSchedule, // prepay
      allowAutoRenew: false, // prepay mvp requirement
    }),
    ...(isAzureMarketplacePricingV1 &&
      (!privateOfferProductRef || privateOfferProductRef === product?.productid
        ? { billingTerm, paymentModel }
        : { billingTerm: null, paymentModel: null })),
  };

  return set(formPath, pricing, data);
}

const MonthsRegex = new RegExp(/\sMonth[s]?/);
const DaysRegex = new RegExp(/\sDay[s]?/);

const parseDuration = (
  duration:
    | FormMetadataPricingDetails['duration']
    | MetadataPricing['duration'] = undefined,
  listingType: ListingTypeType,
): any => {
  if (isNumber(duration)) {
    return duration;
  }

  if (isAmiHourlyAnnual(listingType)) {
    return flow([replace(DaysRegex, ''), toFinite])(duration);
  }

  return flow([replace(MonthsRegex, ''), toFinite])(duration);
};

const toFormUnit = ({
  unit,
  hourlyPrice,
  durationPrice,
}: Unit): FormMetadataUnit => ({
  unit,
  hourlyPrice: hourlyPrice ? toFinite(hourlyPrice) : undefined,
  durationPrice: durationPrice ? toFinite(durationPrice) : undefined,
});

const toFormAzureMarketplacePricingV1Dimension = ({
  apiName,
  name,
  description,
  absolutePrices,
}: AzureOfferPlanMarketplacePricingV1): FormMetadataDimension => ({
  apiName,
  name,
  description,
  absolutePrices,
  price: undefined,
});

const toFormDimension = ({
  name,
  price,
  quantity = null,
  description = null,
  apiName = null,
}: Dimension): FormMetadataDimension => ({
  name: name || undefined,
  price: price ? toFinite(price) : undefined,
  quantity: quantity ? toFinite(quantity) : undefined,
  description: description || undefined,
  apiName: apiName || undefined,
});

const toFormDimensionValue = (pricingVersion: Pricing) => {
  return (
    d: Dimension | AzureOfferPlan | AzureOfferPlanMarketplacePricingV1,
  ): FormMetadataDimension => {
    return pricingVersion === Pricing.AzureMarketplacePricingV1
      ? toFormAzureMarketplacePricingV1Dimension(
          d as AzureOfferPlanMarketplacePricingV1,
        )
      : toFormDimension(d as Dimension);
  };
};

export const calcDimensionValue = ({
  price,
  quantity = null,
}: FormMetadataDimension | Dimension): number =>
  toFinite(price ?? 0) * toFinite(quantity ?? 1);

// strip leading non-alpha and number characters
// strip all invalid characters - note: _ is a valid character, but Upstream ignores this when generating api name. keeping the logic in parity with Upstream
// substring to 36 characters
export const generateAwsDimensionApiName = (name: string): string | undefined =>
  name
    .replace(/^[^A-Za-z]+/, '')
    .replace(/[^A-Za-z\d]/g, '')
    .substring(0, 36);

const toDimension =
  (cloud: Cloud) =>
  ({
    name,
    price,
    quantity = null,
    description = null,
    apiName = null,
  }: FormMetadataDimension): Dimension => ({
    name: trim(toString(name)),
    price: trim(toString(price)),
    quantity: quantity ? trim(toString(quantity)) : null,
    dimensionValue: trim(
      toString(+calcDimensionValue({ name, price, quantity }).toFixed(6)),
    ),
    description: trim(toString(description)),
    apiName:
      cloud === CloudEnum.Aws || cloud === CloudEnum.Gcp
        ? trim(toString(apiName))
        : null,
  });

const toUnit = ({
  unit,
  hourlyPrice,
  durationPrice,
}: FormMetadataUnit): Unit => ({
  unit: trim(toString(unit)),
  hourlyPrice: hourlyPrice ? toFinite(hourlyPrice) : undefined,
  durationPrice: durationPrice ? toFinite(durationPrice) : undefined,
});

const toUsageDimension = ({
  sku,
  description,
  price = null,
  discountPercentage = null,
}: FormMetadataUsageDimension): UsageDimension => ({
  sku: trim(toString(sku)),
  description: trim(toString(description)),
  price: isNil(price) ? null : trim(toString(price)),
  discountPercentage: isNil(discountPercentage)
    ? null
    : trim(toString(discountPercentage)),
});

export const calcMonthlyContractValue = flow([
  map(flow(({ price = 0 }) => price ?? 0, toFinite)),
  sum,
]);

export const calcTotalContractValue = flow(map(calcDimensionValue), sum);

export const isFormDimensionEmpty = (
  dimension: FormMetadataDimension | Dimension,
): boolean =>
  isEmpty(dimension.name) &&
  toFinite(dimension.price) === 0 &&
  isEmpty(dimension?.quantity);

export const isFormUnitEmpty = (unit: FormMetadataUnit | Unit): boolean =>
  isEmpty(unit.unit) &&
  toFinite(unit.hourlyPrice) === 0 &&
  toFinite(unit.durationPrice) === 0;

const getInvoiceDateTypeForAzureMarketplacePricingV1 = (
  index: number,
): ScheduleInvoiceDateType => {
  return index === 0
    ? ScheduleInvoiceDateType.OnStartDate
    : ScheduleInvoiceDateType.Custom;
};

const getInvoiceDateTypeForGcpMarketplacePricingV1 = (
  index: number,
  billingTerm: BillingTermValue,
): ScheduleInvoiceDateType => {
  return index === 0
    ? billingTerm === BillingTermValue.FutureDated
      ? ScheduleInvoiceDateType.OnStartDate
      : ScheduleInvoiceDateType.OnAcceptance
    : ScheduleInvoiceDateType.Custom;
};

const invoiceDateTypeProducerByPricingVersion = {
  [Pricing.AzureMarketplacePricingV1]:
    getInvoiceDateTypeForAzureMarketplacePricingV1,
  [Pricing.GcpMarketplacePricingV1]:
    getInvoiceDateTypeForGcpMarketplacePricingV1,
};

const buildInvoiceDateType = (
  pricingVersion: Pricing,
  billingTerm: BillingTermValue,
  index: number,
) => {
  const dateTypeProducerForPricing =
    invoiceDateTypeProducerByPricingVersion[pricingVersion];

  return dateTypeProducerForPricing
    ? dateTypeProducerForPricing(index, billingTerm)
    : undefined;
};

const pricingVersionsWithNullableFirstDate: Set<Pricing> = new Set<Pricing>([
  Pricing.GcpMarketplacePricingV1,
  Pricing.AzureMarketplacePricingV1,
]);

const buildInvoiceDate = (
  invoiceDate: string,
  pricingVersion: Pricing,
  index: number,
) => {
  if (index === 0 && pricingVersionsWithNullableFirstDate.has(pricingVersion)) {
    return null;
  }

  const { year, month, day } = DateTime.fromISO(invoiceDate).toUTC();

  return DateTime.utc(year, month, day).toISO();
};

const pricingVersionsWithSchedules: Set<Pricing> = new Set<Pricing>([
  Pricing.SimplePricingV1Aws,
  Pricing.GcpMarketplacePricingV1,
  Pricing.AzureMarketplacePricingV1,
]);

const buildPaymentSchedule = (
  pricingVersion: Pricing,
  billingTerm: BillingTermValue,
  paymentSchedule: Schedule[],
): Schedule[] => {
  if (!paymentSchedule || !pricingVersionsWithSchedules.has(pricingVersion)) {
    return [];
  }

  return paymentSchedule.map((schedule, i) => {
    const { invoiceAmount, invoiceDate } = schedule;

    const amount = invoiceAmount
      ? Number.parseFloat(invoiceAmount).toFixed(2)
      : '0';

    return {
      invoiceAmount: amount,
      invoiceDate: buildInvoiceDate(invoiceDate, pricingVersion, i),
      invoiceDateType: buildInvoiceDateType(pricingVersion, billingTerm, i),
    };
  });
};

const formToOfferDurationValuesForAwsOrGcp = (
  billingTerm: BillingTermValueType | '',
  durationValue: number | null,
) => {
  const durationType = getDurationTypeFromFormData(billingTerm, durationValue);

  return {
    durationType,
    durationValue,
    duration: durationValue?.toString(),
  };
};

const pricingVersionsWithAbsolutePriceDimensionShape: Set<Pricing> =
  new Set<Pricing>([
    Pricing.AzurePricingLongTermSaasV1,
    Pricing.AzureMarketplacePricingV1,
  ]);

export function toPrivateOfferShape<
  FormDataType = Record<string, unknown>,
  PrivateOfferType = RecursivePartial<PrivateOffer>,
>(
  data: FormDataType & { pricing?: FormPricingDetails },
  po: PrivateOfferType & Partial<PrivateOfferPricingDetails> & { cloud: Cloud },
  product: Product,
  isEnabledForAWSMultiCurrency: boolean,
  isAzureLTSPricing?: boolean,
  pricingVersionOverride: CloudToPricingVersion = {} as CloudToPricingVersion,
): PrivateOfferType & PrivateOfferPricingDetails {
  if (!po?.cloud) return po;
  if (!data?.pricing) return po;

  const marketplaceFee = flow([
    get('marketplaceFee'),
    cond([
      [isNumber, (d) => d],
      [
        stubTrue,
        flow([
          replace(MonthsRegex, ''),
          toFinite,
          (fee) =>
            Number.parseFloat(fee).toFixed(product?.cloud === 'gcp' ? 6 : 2),
        ]),
      ],
    ]),
    (fee) => fee.toString(),
  ])(data.pricing);

  let pricing = {};

  if (isFormRedHatPricingDetails(data.pricing)) {
    if (!data.pricing.editionId) {
      // No Editions configured
      pricing = null;
    } else if (!data.pricing.billingFrequency) {
      // If only an editionId is selected, there are no charges
      pricing = {
        marketplaceFee,
        accountId: null,
        editionId: data.pricing.editionId,
        billingFrequency: 'MONTHLY',
        offerTerms: [],
        pricingType: getPricingByCloudAndListingType(
          CloudEnum.Redhat,
          product?.listingType as ListingTypeType,
          isAzureLTSPricing,
        ),
      };
    } else {
      pricing = {
        marketplaceFee,
        accountId: null,
        editionId: data.pricing.editionId,
        billingFrequency: data.pricing.billingFrequency.toUpperCase(),
        pricingType: getPricingByCloudAndListingType(
          CloudEnum.Redhat,
          product?.listingType as ListingTypeType,
          isAzureLTSPricing,
        ),
        offerTerms: [
          {
            term: data.pricing.subscriptionTerm,
            termUnit: 'MONTHS',
            charges: flow([
              filter('include'),
              map(({ include, ...charge }) => ({
                ...charge,
                quantity: charge.quantity.toString(),
                unitPrice: charge.unitPrice.toString(),
              })),
            ])(data.pricing.editionCharges),
          },
        ],
      };
    }
  } else {
    const duration = createDurationValue(
      data.pricing.duration,
      product?.cloud as CloudType,
      product?.listingType as ListingTypeType,
      data.pricing.billingTerm,
      data.pricing.serviceStartAt,
      data.pricing.serviceEndAt,
    );

    const pricingVersionOverrideForCloud =
      pricingVersionOverride[product?.cloud];

    const version =
      pricingVersionOverrideForCloud ||
      getPricingByCloudAndListingType(
        product?.cloud as CloudType,
        product?.listingType as ListingTypeType,
        isAzureLTSPricing,
      );

    const isUsingAbsolutePricingDimensionShape =
      pricingVersionsWithAbsolutePriceDimensionShape.has(
        pricingVersionOverrideForCloud,
      );

    const dimensions = isUsingAbsolutePricingDimensionShape
      ? mapFormDataToAzurePricingShape(data?.pricing, version)
      : flow(
          reject(isFormDimensionEmpty),
          map(toDimension(po.cloud)),
        )(data.pricing.dimensions);

    const units = flow(
      reject(isFormUnitEmpty),
      map(toUnit),
    )(data.pricing.units);

    const usageDimensions = map(toUsageDimension, data.pricing.usageDimensions);
    const paymentModel = data.pricing.paymentModel ?? '';
    const billingTerm = data.pricing.billingTerm ?? '';
    const currencyCode = data.pricing.currencyCode ?? null;

    const durationValue = getDurationValueFromFormData(
      billingTerm,
      data?.pricing?.durationValue,
      data?.pricing?.serviceStartAt,
      data?.pricing?.serviceEndAt,
      duration,
    );

    pricing = {
      dimensions,
      units,
      usageDimensions,
      duration,
      showOnEmail: data.pricing.showOnEmail ?? false,
      marketplaceFee,
      version,
      schedule: buildPaymentSchedule(
        version,
        billingTerm as BillingTermValue,
        data.pricing.schedule,
      ),
      billingTerm,
      ...(billingTerm === BillingTermValue.FutureDated && {
        serviceStartAt: convertLocalJSDateToMidnightISOString(
          data?.pricing?.serviceStartAt,
        ),
        serviceEndAt: convertLocalJSDateToMidnightISOString(
          data?.pricing?.serviceEndAt,
        ),
      }),
      ...(product?.cloud === CloudEnum.Aws &&
        billingTerm !== BillingTermValue.FutureDated &&
        !!data?.pricing?.serviceEndAt && {
          serviceEndAt: convertLocalJSDateToMidnightISOString(
            data?.pricing?.serviceEndAt,
          ),
        }),
      ...(product?.cloud === CloudEnum.Aws &&
        billingTerm !== BillingTermValue.FutureDated &&
        !!data?.pricing?.serviceStartAt && {
          serviceStartAt: convertLocalJSDateToMidnightISOString(
            data?.pricing?.serviceStartAt,
          ),
        }),
      ...(product?.cloud === CloudEnum.Aws &&
        !isAmiHourlyAnnual(product?.listingType) && {
          paymentModel,
          ...formToOfferDurationValuesForAwsOrGcp(billingTerm, durationValue),
          ...(isEnabledForAWSMultiCurrency ? { currencyCode } : {}),
        }),
      ...(version === Pricing.GcpMarketplacePricingV1 && {
        paymentModel: PaymentModelValue.PaymentSchedule, // prepay
        allowAutoRenew: false, // prepay mvp requirement
      }),
      ...(version === Pricing.GcpMarketplacePricingV1 &&
        billingTerm !== BillingTermValue.FutureDated && {
          ...formToOfferDurationValuesForAwsOrGcp(billingTerm, durationValue),
        }),
      ...(version === Pricing.AzureMarketplacePricingV1 && {
        paymentModel,
      }),
    } as MetadataPricing;
  }

  const path = product?.cloud === 'redhat' ? 'pricing' : 'metadata.pricing';

  return set(path, pricing, po);
}

function toRedHatFormShape(
  pricing: Partial<RedHatPricing>,
): FormRedHatPricingDetails {
  if (!pricing) return null;

  const subscriptionTerm = get('offerTerms[0].term', pricing);
  const editionCharges = flow([
    get('offerTerms[0].charges'),
    map(
      (
        charge: FormRedhatPricingDetailsEditionCharge,
      ): FormRedhatPricingDetailsEditionCharge => ({
        ...charge,
        quantity: toFinite(charge.quantity),
        include: true,
      }),
    ),
  ])(pricing);

  return {
    marketplaceFee: toFinite(pricing.marketplaceFee ?? 0),
    editionId: pricing.editionId,
    subscriptionTerm,
    billingFrequency: pricing.billingFrequency?.toLowerCase(),
    editionCharges,
  };
}

export function toFormShape<
  PrivateOfferType = RecursivePartial<PrivateOffer>,
  FormDataType = Record<string, unknown>,
>(
  privateOffer: PrivateOfferType & RecursivePartial<PrivateOfferPricingDetails>,
  formData: FormDataType & { pricing?: RecursivePartial<FormPricingDetails> },
): FormDataType & { pricing: FormPricingDetails } {
  return flow([
    cond([
      [
        flow([get('cloud'), isEqual('redhat')]),
        flow([get(redHatPrivateOfferPath), toRedHatFormShape]),
      ],
      [stubTrue, get(metadataPrivateOfferPath)],
    ]),
    set(formPath, partial.placeholder, formData),
  ])(privateOffer);
}

const toAbsolutePriceFromBillingTermAndPaymentModel =
  (pricing?: FormMetadataPricingDetails) =>
  (ap: AzureAbsolutePrice): AzureAbsolutePrice => {
    const billingTerm = getAbsolutePriceBillingTerm(pricing?.billingTerm);
    const paymentOption = getAbsolutePricePaymentOption(
      billingTerm,
      pricing?.paymentModel,
    );

    return {
      billingTerm,
      paymentOption,
      pricePerPaymentInUsd: ap.pricePerPaymentInUsd,
    };
  };

const getCompactedAbsolutePrices = (
  version: Pricing,
  formAbsolutePrices: AzureAbsolutePrice[],
  pricing: FormMetadataPricingDetails,
): AzureAbsolutePrice[] => {
  const absolutePricesInOfferShape =
    version === Pricing.AzureMarketplacePricingV1
      ? formAbsolutePrices.map(
          toAbsolutePriceFromBillingTermAndPaymentModel(pricing),
        )
      : formAbsolutePrices;

  return absolutePricesInOfferShape?.length > 0
    ? compact(absolutePricesInOfferShape)
    : [];
};

export function mapFormDataToAzurePricingShape(
  pricing: FormMetadataPricingDetails,
  version: Pricing,
): AzureOfferPlan[] | AzureOfferPlanMarketplacePricingV1[] {
  if (pricing?.dimensions.length < 1) return [];

  const {
    apiName,
    name,
    description,
    absolutePrices: formAbsolutePrices = [],
  } = pricing.dimensions[0];

  // azure-marketplace-pricing-v1 absolutePrice billingTerm and paymentOption are
  // computed by the cloud-agnostic billingTerm and paymentModel and not the form.
  const absolutePrices = getCompactedAbsolutePrices(
    version,
    formAbsolutePrices,
    pricing,
  );

  const commonDimensionProperties: AzureOfferPlan = {
    name,
    absolutePrices,
    discountPercentage: null,
  };

  return version === Pricing.AzureMarketplacePricingV1
    ? [{ apiName, description, ...commonDimensionProperties }]
    : [commonDimensionProperties];
}

export const BILLING_TERM_TO_DURATION_TYPE = {
  [BillingTermValue.OneMonth]: DurationTypeValue.Month,
  [BillingTermValue.OneYear]: DurationTypeValue.Months,
  [BillingTermValue.TwoYears]: DurationTypeValue.Months,
  [BillingTermValue.ThreeYears]: DurationTypeValue.Months,
  [BillingTermValue.Custom]: DurationTypeValue.Months,
  [BillingTermValue.FutureDated]: DurationTypeValue.Months,
};

export function getDurationTypeFromFormData(
  billingTerm: BillingTermValueType | string,
  durationValue: number,
): string {
  if (!billingTerm) return;

  if (billingTerm === BillingTermValue.Custom)
    return durationValue === 1
      ? DurationTypeValue.Month
      : DurationTypeValue.Months;

  return BILLING_TERM_TO_DURATION_TYPE[billingTerm];
}

export const BILLING_TERM_TO_DURATION_VALUE = {
  [BillingTermValue.OneMonth]: 1,
  [BillingTermValue.OneYear]: 12,
  [BillingTermValue.TwoYears]: 24,
  [BillingTermValue.ThreeYears]: 36,
};

export function getDurationValueFromFormData(
  billingTerm: BillingTermValueType | string,
  durationValue: number,
  serviceStartAt: Date | string,
  serviceEndAt: Date | string,
  duration: number | string,
): number {
  if (!billingTerm) {
    if (isNumber(duration)) return duration;
    return;
  }

  if (billingTerm === BillingTermValue.Custom) return durationValue;

  if (billingTerm === BillingTermValue.FutureDated)
    return getMonthsDifferenceFromJSDates(serviceStartAt, serviceEndAt);

  return BILLING_TERM_TO_DURATION_VALUE[billingTerm];
}

export const DEFAULT_DURATION_MONTHS_MIN = 1;
export const GCP_PREPAY_DURATION_MONTHS_MIN = 2;
export const DEFAULT_DURATION_MONTHS_MAX = 60;
export const getDurationMinMax = (
  cloud: Cloud,
  paymentModel: PaymentModelValueType,
  billingTerm: BillingTermValue,
) => {
  let minMonths: number;
  let maxMonths: number;

  if (
    cloud === CloudEnum.Gcp &&
    paymentModel === PaymentModelValue.PaymentSchedule &&
    billingTerm === BillingTermValue.Custom
  ) {
    minMonths = GCP_PREPAY_DURATION_MONTHS_MIN;
  }

  return {
    min: minMonths || DEFAULT_DURATION_MONTHS_MIN,
    max: maxMonths || DEFAULT_DURATION_MONTHS_MAX,
  };
};
