import { Box, Grid, Typography } from 'vendor/material';
import { useStyles } from './PaymentScheduleTab.style';
import { Tag } from '@tackle-io/platform-ui';
import { format, isBefore } from 'date-fns';
import { ampli } from 'utils/analytics/ampli';
import { useEffect, useState } from 'react';

interface PaymentScheduleTabProps {
  contract: any;
}

const formatCurrency = (amount?: number | null | undefined): string => {
  if (amount === null || amount === undefined) return '--';
  return `$${amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const PaymentScheduleTab: React.FC<PaymentScheduleTabProps> = ({
  contract,
}) => {
  const classes = useStyles();
  const [eventSent, setEventSent] = useState(false);

  useEffect(() => {
    if (contract && !eventSent) {
      ampli.contractDetailTabViewed({
        marketplace: contract?.cloudMarketplace,
        contract_status: contract?.contractStatus,
        offer_origination: contract?.offerType,
        pricing_model: contract?.pricingModel,
        tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
        contract_detail_tab: 'Payment Schedule',
      });
      setEventSent(true);
    }
  }, [contract, eventSent]);

  if (!contract) {
    return null;
  }
  const paymentSchedule = contract?.paymentSchedule?.schedule || [];
  const sortedSchedule = [...paymentSchedule].sort(
    (a, b) =>
      new Date(a.chargeDate).getTime() - new Date(b.chargeDate).getTime(),
  );

  return (
    <Box className={classes.container}>
      <Grid container className={classes.header} alignItems="center">
        <Grid item xs={1}>
          <Typography className={classes.headerItem}>Number</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.headerItem}>Invoice date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.headerItem}>Status</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.headerItem} align="right">
            Payment amount
          </Typography>
        </Grid>
      </Grid>

      {sortedSchedule.map((invoice, index) => {
        const status = isBefore(new Date(invoice.chargeDate), new Date())
          ? 'Invoice sent'
          : 'Scheduled';
        return (
          <Grid
            container
            className={classes.row}
            alignItems="center"
            key={index}
          >
            <Grid item xs={1}>
              <Typography>{index + 1}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {format(new Date(invoice.chargeDate), 'MM/DD/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Tag className={classes.statusTag}>{status}</Tag>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right">
                {formatCurrency(Number(invoice.chargeAmount))}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default PaymentScheduleTab;
