import React, { useCallback, useMemo } from 'react';
import { FormSection, Select } from '@tackle-io/platform-ui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Cloud } from 'utils/cloudTypes';
import { useSelectedProduct } from '../../../stores/products/hooks';
import { GcpProduct, GcpDimension } from 'stores/products/typings';
import { flow, fromPairs, map, filter } from 'lodash/fp';
import { Plan } from '../../../stores/products/typings/GcpProduct';

interface FieldGcpPlanProps {
  productId: string;
  onGcpPlanChanged: () => void;
}

const nameFormPath = 'pricing.dimensions[0].name';
const apiNameFormPath = 'pricing.dimensions[0].apiName';

const selectId = 'pricing.dimensions.0.name';

const setValueOptions = { shouldDirty: true };

const noOpRenderer = () => null;
const buildDisplayNamePlanIdLookup = (
  plans: Plan[],
  productDimensions: GcpDimension[],
) => {
  const planNames = plans?.map((p) => p.name) || [];

  return flow(
    filter(
      (dimension: GcpDimension) =>
        planNames.includes(dimension.displayName) &&
        !/P[123]Y$/.test(dimension.name),
    ),
    map((dimension: GcpDimension) => {
      const gcpPlanId = (dimension?.name?.match(/[^/]*$/) || [])[0] || '';
      return [dimension.displayName, gcpPlanId];
    }),
    fromPairs,
  )(productDimensions);
};

const GcpPlanField: React.FC<FieldGcpPlanProps> = ({
  productId,
  onGcpPlanChanged,
}) => {
  const { control, errors, setValue } = useFormContext();
  const { content: selectedProduct } = useSelectedProduct(productId, Cloud.Gcp);
  const dimensions = useWatch({ name: 'pricing.dimensions', control });

  const defaultValue: string = dimensions[0]?.name ?? '';
  const { plans = [], dimensions: productDimensions = [] } =
    selectedProduct as GcpProduct;

  const planNameToPlanIdLookup = useMemo(
    () => buildDisplayNamePlanIdLookup(plans, productDimensions),
    [plans, productDimensions],
  );

  const planOptions = useMemo(
    () =>
      plans.map((p: Plan) => (
        <option value={p.name} key={p.name}>
          {p.name}
        </option>
      )),
    [plans],
  );

  const onChange = useCallback(
    (e) => {
      const {
        target: { value: planName = '' },
      } = e;
      const planId = planNameToPlanIdLookup[planName] ?? '';

      setValue(nameFormPath, planName, setValueOptions);
      setValue(apiNameFormPath, planId, setValueOptions);

      onGcpPlanChanged();
    },
    [planNameToPlanIdLookup, setValue, onGcpPlanChanged],
  );

  const planSelectRenderer = useCallback(() => {
    const error = errors.pricing?.dimensions?.[0]?.name?.message;

    return (
      <Select
        id={selectId}
        label="Plan"
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder="Select a plan"
        error={error}
      >
        <option value={''}>Select a plan</option>
        {planOptions}
      </Select>
    );
  }, [errors, defaultValue, onChange, planOptions]);

  return (
    <FormSection title="Select a plan" mb={1.5}>
      <Controller
        name={nameFormPath}
        control={control}
        defaultValue={defaultValue}
        render={planSelectRenderer}
      />
      <Controller
        name={apiNameFormPath}
        control={control}
        defaultValue={defaultValue}
        render={noOpRenderer}
      />
    </FormSection>
  );
};

export default GcpPlanField;
