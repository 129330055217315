import {
  GcpProduct,
  Metric,
  MetricPricePerUnit,
  Plan,
} from '../../../../stores/products/typings/GcpProduct';
import {
  FormMetadataPricingDetails,
  FormMetadataUsageDimension,
} from '../../utils';
import { toFormUsageDimension } from '../usageDimensionUtils';
import { UsageDimension } from '../../../../stores/privateOffers/typings';
import { toFinite } from 'lodash';

const planNameMatches = (formPlanName: string) => (p: Plan) => {
  return p.name === formPlanName;
};

const pricePerUnitIsForPlan = (selectedPlanId: string) => {
  return (ppu: MetricPricePerUnit) => {
    return ppu.planId === selectedPlanId;
  };
};

const metricsPricingHasPricingForPlan = (selectedPlanId: string) => {
  return (m: Metric) => {
    return m.pricePerUnit.some(pricePerUnitIsForPlan(selectedPlanId));
  };
};

const metricPricingToFormUsageDimension = (selectedPlanId: string) => {
  return (m: Metric) => {
    const { sku, name: description } = m;

    const { price } = m.pricePerUnit.find(
      pricePerUnitIsForPlan(selectedPlanId),
    );

    return {
      sku,
      price,
      description,
      discountPercentage: 0,
    };
  };
};

const getUsageDimensionsFromProduct = (
  product: GcpProduct,
  formPlanName: string,
): FormMetadataUsageDimension[] => {
  const { plans, metricsPricing } = product;
  const selectedPlan = plans?.find(planNameMatches(formPlanName));
  const selectedPlanId = selectedPlan?.planId;

  return metricsPricing
    ?.filter(metricsPricingHasPricingForPlan(selectedPlanId))
    .map(metricPricingToFormUsageDimension(selectedPlanId));
};

const usageDimensionMatchesDescriptionAndPrice =
  (ud: FormMetadataUsageDimension) =>
  (usageDimensionFromPlan: FormMetadataUsageDimension): boolean => {
    return (
      ud.description === usageDimensionFromPlan.description &&
      toFinite(ud.price) === toFinite(usageDimensionFromPlan.price)
    );
  };

const formDimensionIsForPlan =
  (usageDimensionsForPlan: FormMetadataUsageDimension[]) =>
  (formUsageDimension: FormMetadataUsageDimension): boolean => {
    return usageDimensionsForPlan.some(
      usageDimensionMatchesDescriptionAndPrice(formUsageDimension),
    );
  };

const usageDimensionsAreForPlan = (
  formUsageDimensions: FormMetadataUsageDimension[],
  usageDimensionsForPlan: FormMetadataUsageDimension[],
): boolean => {
  return formUsageDimensions.every(
    formDimensionIsForPlan(usageDimensionsForPlan),
  );
};

const shouldUseFormUsageDimensions = (
  formUsageDimensions: FormMetadataUsageDimension[] | null,
  usageDimensionsForPlan: FormMetadataUsageDimension[],
): boolean => {
  return (
    formUsageDimensions?.length > 0 &&
    !!usageDimensionsForPlan &&
    usageDimensionsAreForPlan(formUsageDimensions, usageDimensionsForPlan)
  );
};

export const getDefaultGCPUsageDimensions = <FormDataType>(
  product: GcpProduct,
  formData: FormDataType & { pricing?: Partial<FormMetadataPricingDetails> },
): FormMetadataUsageDimension[] => {
  const formPlanName = formData?.pricing?.dimensions?.[0]?.name;

  const usageDimensionsForPlan = formPlanName
    ? getUsageDimensionsFromProduct(product, formPlanName)
    : undefined;

  const formUsageDimensions =
    formData?.pricing?.usageDimensions?.map(toFormUsageDimension);

  const useFormUsageDimensions = shouldUseFormUsageDimensions(
    formUsageDimensions,
    usageDimensionsForPlan,
  );

  return useFormUsageDimensions ? formUsageDimensions : usageDimensionsForPlan;
};

const calculateMargin = (discountPercentage: number): number => {
  return discountPercentage === 0 ? 0 : 1 - discountPercentage / 100;
};

export const calculateNewDiscountPrice = (
  price: number,
  discountPercentage: number,
): number => {
  const discountMargin = calculateMargin(discountPercentage);

  return price * discountMargin;
};

export class UsageDimensionDiscountsSet {
  public discountsSet: Set<number>;

  private toDiscountPercentage = (
    ud: UsageDimension | FormMetadataUsageDimension,
  ): number => toFinite(ud.discountPercentage);

  constructor(
    usageDimensions: UsageDimension[] | FormMetadataUsageDimension[],
  ) {
    const discounts = usageDimensions.map(this.toDiscountPercentage);

    this.discountsSet = new Set<number>(discounts);
  }

  public allDiscountsAreEqual = (): boolean => this.discountsSet.size === 1;

  public discountsSetHasZeroOrNull = (): boolean => {
    return this.discountsSet.has(0) || this.discountsSet.has(null);
  };

  public getFirstDiscountPercentageValue = (): number => {
    return this.discountsSet.values().next().value;
  };

  public firstDiscountPercentageValueIsGreaterThanZero = (): boolean => {
    return this.getFirstDiscountPercentageValue() > 0;
  };
}
