import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useCallback } from 'react';
import { AceOpportunityDeliveryModelEnum } from 'packages/cosell/src/types/enums';

/**
 *  this specific UUID informs the UI that the SaaS documentation is required to launch,
 *  if the deliveryModel is SaaS or PaaS
 */
const SAAS_DOCUMENTATION_CLOSE_WORKFLOW_UUID =
  '574dea55-918c-4bef-b5a4-78c74790f172';

/**
 *  Hook to check if SaaS documentation is required to launch
 *  - makes request to settings to check if vendor has specific custom_workflow uuid
 *  - provides a utility function to check if sass documentation is required to launch (cannot use isSRRP name)
 */
export const useSaasDocumentationRequiredToLaunchCheck = ({
  vendorId,
}: {
  vendorId: string;
}) => {
  const {
    data: hasSaasDocumentationWorkflow,
    isError,
    isLoading,
  } = useQuery({
    queryFn: coSellClient.getSettings,
    // vendorId update forces a new query to be made
    queryKey: ['check-hasSaasDocumentationWorkflow', vendorId],
    select(data) {
      return !!data.customWorkflows?.includes(
        SAAS_DOCUMENTATION_CLOSE_WORKFLOW_UUID,
      );
    },
  });

  const isSaasDocumentationRequiredToLaunch = useCallback(
    ({ deliveryModels = [] }: { deliveryModels: string[] }) =>
      hasSaasDocumentationWorkflow &&
      deliveryModels.includes(AceOpportunityDeliveryModelEnum.SAAS_or_PAAS),
    [hasSaasDocumentationWorkflow],
  );

  return {
    /**
     *  a function to check if sass documentation is required to launch (cannot use isSRRP name)
     *  Requires you to pass in deliveryModels, since the deliveryModels could be pulled from the opportunity or the form values,
     *  based on location this is used.
     *  - check if hasSaasDocumentationWorkflow is true
     *  - checks if deliveryModels includes SAAS_or_PAAS
     */
    isSaasDocumentationRequiredToLaunch,
    isErrorLoadingCustomWorkflow: isError,
    isCustomWorkflowLoading: isLoading,
  };
};
