import React, { ChangeEvent } from 'react';
import { Grid } from 'vendor/material';
import { GcpMetricDiscountMode } from './GcpMetricDiscountMode';
import { FormUsageDimension } from '../../../../pages/PrivateOffers/utils/formatFormData';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '../../TextField/TextField';
import GcpUsageDimensionNewDiscount from './GcpUsageDimensionNewDiscount';
import classNames from 'classnames';
import useStyles from './GcpUsageDimension.styles';
import { usageDimensionsFormPath } from '../../utils';
import { toFinite } from 'lodash';

interface GcpUsageDimensionProps {
  selectedDiscountMode: GcpMetricDiscountMode;
  equalDiscount: number;
  index: number;
  usageDimension: FormUsageDimension;
}

const onDiscountPercentageChanged =
  (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: newDiscountPercentage },
    } = e;

    onChange(toFinite(newDiscountPercentage));
  };

const GcpUsageDimension: React.FC<GcpUsageDimensionProps> = ({
  selectedDiscountMode,
  equalDiscount,
  index,
  usageDimension,
}) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const discountPercentageFieldClasses = classNames({
    [classes.hiddenField]:
      selectedDiscountMode !== GcpMetricDiscountMode.ApplyDiscountIndividually,
  });

  return (
    <Grid container spacing={1}>
      <Controller
        name={`${usageDimensionsFormPath}.${index}.sku`}
        control={control}
        defaultValue={usageDimension.sku || null}
        render={() => null}
      />
      <Grid item>
        <Controller
          name={`${usageDimensionsFormPath}.${index}.description`}
          control={control}
          defaultValue={usageDimension.description}
          render={({ ref, ...props }) => {
            return <TextField {...props} label="Name" readOnly />;
          }}
        />
      </Grid>
      <Grid item>
        <Controller
          name={`${usageDimensionsFormPath}.${index}.price`}
          control={control}
          defaultValue={usageDimension.price}
          render={({ ref, ...props }) => {
            return (
              <TextField
                {...props}
                label="List price"
                type="number"
                mode="numberformat"
                readOnly
              />
            );
          }}
        />
      </Grid>
      <Grid item className={discountPercentageFieldClasses}>
        <Controller
          name={`${usageDimensionsFormPath}.${index}.discountPercentage`}
          control={control}
          defaultValue={usageDimension.discountPercentage}
          render={({ ref, onChange, ...props }) => {
            return (
              <TextField
                {...props}
                label="Discount dimension"
                type="number"
                mode="numberformat"
                numberPrefix=""
                suffix="%"
                onChange={onDiscountPercentageChanged(onChange)}
                decimalScale={2}
                fixedDecimalScale
              />
            );
          }}
        />
      </Grid>
      {selectedDiscountMode !== GcpMetricDiscountMode.NoDiscount && (
        <Grid item>
          <GcpUsageDimensionNewDiscount
            selectedDiscountMode={selectedDiscountMode}
            equalDiscount={equalDiscount}
            index={index}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default GcpUsageDimension;
