import { Loader } from '@tackle-io/platform-ui';
import { useQuery } from '@tanstack/react-query';
import { CheckCircle } from 'mdi-material-ui';
import { useConnectedAppCallbackStyles } from './ConnectedAppCallback.styles';
import { Icon } from 'vendor/material';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import { unescape } from 'lodash';
import { useAuth } from 'vendor/auth0';

const CODE_PARAM = 'code';

const getSalesforceToken = async (
  salesforceInstanceUrl: string,
  code: string,
  getTackleTokenFunction: () => Promise<string | undefined>,
) => {
  const body = {
    loginURL: salesforceInstanceUrl,
    authorizationCode: unescape(code),
    redirectURL:
      process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_REGISTER_REDIRECT_URL,
  };

  const token = await getTackleTokenFunction();

  const resp = await fetch(
    process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_REGISTER_API_URL,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    },
  );

  const data = await resp.json();
  if (resp.ok) {
    return data;
  } else {
    return Promise.reject(data);
  }
};

const ConnectedAppCallback = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get(CODE_PARAM).toString();
  const classes = useConnectedAppCallbackStyles();
  const salesforceInstanceUrl = document.referrer;
  const { getAccessTokenSilently } = useAuth();

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [
      salesforceInstanceUrl,
      code,
      getAccessTokenSilently,
      'salesforceOAuthCallback',
    ],
    queryFn: () =>
      getSalesforceToken(salesforceInstanceUrl, code, getAccessTokenSilently),
    enabled: true,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <div className={classes.successContainer}>
        <Icon fontSize="large">
          <CheckCircle />
        </Icon>
        <h2>Successfully connected to Salesforce</h2>
        <button
          onClick={() => {
            window.opener?.sendMessage(JSON.stringify(data));
          }}
        >
          Close Window
        </button>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classes.errorContainer}>
        <h2>There was an issue connecting to Salesforce</h2>
        <p>Please try again</p>
        <button
          onClick={() => {
            window.opener.sendMessage(JSON.stringify(data));
          }}
        >
          Close Window
        </button>
      </div>
    );
  }
};

export const WrappedConnectedAppCallback = () => {
  return (
    <ReactQueryClientProvider>
      <ConnectedAppCallback />
    </ReactQueryClientProvider>
  );
};
