import { isUndefined, isEmpty } from 'lodash-es';

export const removeUndefinedOrEmptyObjectProperties = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = removeUndefinedOrEmptyObjectProperties(obj[key]);

      // If the property is an object and is now empty or null, delete it
      if (
        isUndefined(obj[key]) ||
        obj[key] === null ||
        (typeof obj[key] !== 'number' && isEmpty(obj[key])) ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    }
  }

  return obj;
};
