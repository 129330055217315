import { Box, Grid } from 'vendor/material';
import { useFormikContext } from 'formik';
import {
  AiTextFormField,
  DateFormField,
  EditModalType,
  MultiSelectFormField,
  RadioCheckboxGroupFormField,
  SingleSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';

import {
  AcePartnerNeedType,
  AceOpportunityTypeEnum,
  OpportunityIndustryEnum,
  CompetitiveTrackingEnum,
  FeatureFlagState,
  AceOpportunityReviewStatusEnum,
} from 'packages/cosell/src/types/enums';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { CoSellFeatureFlags } from 'utils/features';
import { UnifiedOpportunityFormSection } from '../UnifiedOpportunityFormSection';
import {
  AssignmentIcon,
  ChecklistIcon,
  PersonIcon,
} from 'packages/cosell/assets';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { OTHER } from '../FormFieldsConstants';
import {
  CreateAceOpportunityFormValues,
  UpdateAceOpportunityFormValues,
} from './AceOpportunityFormValues';
import {
  getAwsFundingUsedOptions,
  getCoSellStageOptions,
  getNationalSecurityOptions,
  getOpportunitySourceOptions,
  getOpportunityTypeOptions,
  handleOpportunitySourceChange,
  handleOpportunityTypeChange,
  handlePartnerNeedTypeChange,
  isFieldEditable,
  UNITED_STATES_COUNTRY_CODE,
} from './helpers';
import { useEffect, useState } from 'react';
import { scrollToFirstError } from 'packages/cosell/src/utilities/scroll/scrollToFirstError';
import { isEmpty } from 'lodash';
import { MarketingSourceEnum } from 'packages/cosell/src/types/enums/MarketingSourceEnum';
import {
  AdditionalComments,
  ExpectedMonthlyAwsRevenue,
  Solutions,
  TargetCloseDate,
} from './SharedFormFields';
import {
  CurrencyCode,
  CustomerSoftwareValue,
  EffectiveDate,
  ExpirationDate,
  ProcurementType,
  MarketplaceOfferId,
  ReasonForClosing,
} from './EditModalOnlyFields';
import { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { ExpectedCustomerSpendFieldSet } from './ExpectedCustomerSpendFieldSet';
import {
  coSellAceOpportunityFormFieldsDataId,
  coSellCreateEditPageDataId,
} from 'packages/cosell/src/utilities/intercomEnums';

const useCoSellFeatureFlags = () => {
  const { loading: ampliloading, flags: ampliflags } = useAmpliFeatureFlags();
  return {
    ampliloading,
    showAiGenerateButton:
      ampliflags[CoSellFeatureFlags.BETA_AI_COSELL_DESCRIPTION] ===
      FeatureFlagState.ON
        ? true
        : false,
  };
};

const renderEditModalForm = ({
  editModalType,
  values,
  status,
  awsEnumListMap,
  setFieldValue,
  enableSaasDocumentationFields,
}: {
  editModalType: EditModalType;
  values: UpdateAceOpportunityFormValues;
  status: AceOpportunityReviewStatusEnum;
  awsEnumListMap: AwsEnumListResponse;
  setFieldValue: (field: string, value: any) => void;
  enableSaasDocumentationFields: boolean;
}) => {
  switch (editModalType) {
    case EditModalType.LAUNCH:
      return (
        <div>
          {!enableSaasDocumentationFields && (
            <Box mb={2}>
              <ExpectedMonthlyAwsRevenue status={status} />
            </Box>
          )}
          <Box mb={2}>
            <TargetCloseDate status={status} />
          </Box>
          <Box mb={2}>
            <Solutions status={status} setFieldValue={setFieldValue} />
          </Box>
          <Box mb={2}>
            <MarketplaceOfferId status={status} />
          </Box>
          {enableSaasDocumentationFields && (
            <>
              <Box mb={2}>
                <ProcurementType
                  status={status}
                  awsEnumListMap={awsEnumListMap}
                />
              </Box>
              <Box mb={2}>
                <CustomerSoftwareValue status={status} />
              </Box>
              <Box mb={2}>
                <CurrencyCode awsEnumListMap={awsEnumListMap} />
              </Box>
              <Box mb={2}>
                <EffectiveDate status={status} />
              </Box>
              <Box mb={2}>
                <ExpirationDate status={status} />
              </Box>
            </>
          )}
        </div>
      );
    case EditModalType.EDIT_LAUNCHED:
      return (
        <div>
          <Box mb={2}>
            <ProcurementType status={status} awsEnumListMap={awsEnumListMap} />
          </Box>
          <Box mb={2}>
            <AdditionalComments status={status} />
          </Box>
          {enableSaasDocumentationFields && (
            <>
              <Box mb={2}>
                <CustomerSoftwareValue status={status} />
              </Box>
              <Box mb={2}>
                <CurrencyCode awsEnumListMap={awsEnumListMap} />
              </Box>
              <Box mb={2}>
                <EffectiveDate status={status} />
              </Box>
              <Box mb={2}>
                <ExpirationDate status={status} />
              </Box>
            </>
          )}
        </div>
      );
    case EditModalType.CLOSE_LOST:
      return (
        <div>
          <Box mb={2}>
            <ReasonForClosing status={status} awsEnumListMap={awsEnumListMap} />
          </Box>
          <Box mb={2}>
            <AdditionalComments status={status} />
          </Box>
        </div>
      );
  }
};

const AceOpportunityFormFields = ({
  editModalType = null,
}: {
  editModalType?: EditModalType;
}) => {
  const { values, setFieldValue, errors, isValid, isValidating, submitCount } =
    useFormikContext<
      CreateAceOpportunityFormValues | UpdateAceOpportunityFormValues
    >();
  const { awsEnumListMap, isSaasDocumentationRequiredToLaunch, opportunityId } =
    useOpportunity();
  const { aceOpportunityQuery } = useAceOpportunity({ opportunityId });
  const opportunity = aceOpportunityQuery?.data;

  const { ampliloading, showAiGenerateButton } = useCoSellFeatureFlags();
  const [hasScrolledToFirstError, setHasScrolledToFirstError] = useState(false);
  useEffect(() => {
    const shouldScrollToError =
      !isValid &&
      !isEmpty(errors) &&
      submitCount > 0 &&
      !hasScrolledToFirstError;
    if (!shouldScrollToError) {
      return;
    }

    const hasScrolled = scrollToFirstError();
    setHasScrolledToFirstError(hasScrolled);
  }, [errors, isValidating, isValid, submitCount, hasScrolledToFirstError]);

  useEffect(() => {
    setHasScrolledToFirstError(false);
  }, [submitCount]);

  useEffect(() => {
    const shouldScrollToError = !isValid && !isEmpty(errors) && submitCount > 0;
    if (!shouldScrollToError) {
      return;
    }

    scrollToFirstError();
  }, [errors, isValidating, isValid, submitCount]);

  const isOppFromMarketingActivity =
    values?.marketingSource === MarketingSourceEnum.MARKETING_ACTIVITY;

  if (editModalType) {
    return renderEditModalForm({
      editModalType,
      values,
      status: opportunity?.lifeCycle?.reviewStatus,
      awsEnumListMap,
      setFieldValue,
      enableSaasDocumentationFields: isSaasDocumentationRequiredToLaunch({
        deliveryModels: values.deliveryModels,
      }),
    });
  }
  return (
    <>
      <div>
        {/* Section 1: Customer Details */}
        {opportunity?.lifeCycle?.reviewStatus ===
          AceOpportunityReviewStatusEnum.APPROVED && (
          <UnifiedOpportunityFormSection
            title="Co-Sell stage"
            titleIcon={<ChecklistIcon />}
            dataId={coSellCreateEditPageDataId.CO_SELL_STAGE_SECTION_HEADER}
          >
            <Grid item xs={12}>
              <SingleSelectFormField
                fieldId="stage"
                filterSelectedOptions={true}
                label="Co-Sell stage"
                options={getCoSellStageOptions(
                  awsEnumListMap?.['lifeCycle.stage'],
                )}
                required
                helperText="Share progress with your cloud partner by updating the Co-Sell stage. Please note that co-sells can only be “Launched” or marked “Closed Lost” on the details page."
              />
            </Grid>
          </UnifiedOpportunityFormSection>
        )}

        {/* Section 1: Customer Details */}
        <UnifiedOpportunityFormSection
          title="Customer details"
          titleIcon={<OfficeBuilding />}
          dataId={coSellCreateEditPageDataId.CUSTOMER_DETAILS_SECTION_HEADER}
        >
          <Grid item xs={12}>
            <TextFormField
              name="duns"
              label="Customer data universal number system (DUNS)"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_DUNS_NUMBER}
              helperText="Customer DUNS is 9 digits. For example: 123456789"
              disabled={
                !isFieldEditable({
                  name: 'duns',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerCompanyName"
              label="Customer company name"
              dataId={
                coSellAceOpportunityFormFieldsDataId.CUSTOMER_COMPANY_NAME
              }
              disabled={
                !isFieldEditable({
                  name: 'customerCompanyName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="industry"
              label="Industry vertical"
              options={awsEnumListMap?.['customer.account.industry']}
              onChange={() => {
                setFieldValue('industryOther', null);
                setFieldValue('nationalSecurity', null);
              }}
              required
              disabled={
                !isFieldEditable({
                  name: 'industry',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.industry === OpportunityIndustryEnum.OTHER ? (
            <Grid item xs={12}>
              <TextFormField
                name="industryOther"
                label="Industry other"
                dataId={coSellAceOpportunityFormFieldsDataId.INDUSTRY_OTHER}
                disabled={
                  !isFieldEditable({
                    name: 'industryOther',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          {values.industry === OpportunityIndustryEnum.GOVERNMENT &&
          values.countryCode === UNITED_STATES_COUNTRY_CODE ? (
            <Grid item xs={12}>
              <RadioCheckboxGroupFormField
                id="nationalSecurity"
                label="Classified national security information"
                radioOptions={getNationalSecurityOptions(
                  awsEnumListMap?.['nationalSecurity'],
                )}
                helperText="Specify whether this opportunity contains classified National Security information."
                required
                name={'nationalSecurity'}
                disabled={
                  !isFieldEditable({
                    name: 'nationalSecurity',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="customerWebsite"
              label="Customer website"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_WEBSITE}
              disabled={
                !isFieldEditable({
                  name: 'customerWebsite',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="countryCode"
              data-testid="countrySingleSelectFormField"
              label="Country"
              onChange={() => setFieldValue('nationalSecurity', null)}
              options={awsEnumListMap?.['customer.account.address.countryCode']}
              disabled={
                !isFieldEditable({
                  name: 'countryCode',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="postalCode"
              label="Postal code"
              dataId={coSellAceOpportunityFormFieldsDataId.POSTAL_CODE}
              helperText={
                values.countryCode === UNITED_STATES_COUNTRY_CODE &&
                'United States postal code must be in 99999 OR 99999-9999 format.'
              }
              disabled={
                !isFieldEditable({
                  name: 'countryCode',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              required
            />
          </Grid>
          {values.countryCode === UNITED_STATES_COUNTRY_CODE ? (
            <Grid item xs={12}>
              <SingleSelectFormField
                data-testid="stateTextFormField"
                fieldId="state"
                label="State/Province"
                options={
                  awsEnumListMap?.['customer.account.address.stateOrRegion']
                }
                disabled={
                  !isFieldEditable({
                    name: 'state',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
                required
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="city"
              label="City"
              dataId={coSellAceOpportunityFormFieldsDataId.CITY}
              disabled={
                !isFieldEditable({
                  name: 'city',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="address"
              label="Address"
              dataId={coSellAceOpportunityFormFieldsDataId.ADDRESS}
              disabled={
                !isFieldEditable({
                  name: 'address',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 2: Project details */}
        <UnifiedOpportunityFormSection
          title="Project details"
          titleIcon={<AssignmentIcon />}
          dataId={coSellCreateEditPageDataId.PROJECT_DETAILS_SECTION_HEADER}
        >
          <Grid item xs={12}>
            {/* TODO: Update when component is merged */}
            <RadioCheckboxGroupFormField
              id="partnerNeedType"
              label="Partner primary need from AWS"
              radioOptions={[
                {
                  display: 'Co-sell with AWS',
                  value: AcePartnerNeedType.CO_SELL_WITH_AWS,
                  helperText:
                    'Share the opportunity with AWS to receive deal assistance and support.',
                },
                {
                  display: 'Do not need support from AWS sales rep',
                  value:
                    AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
                  helperText:
                    'Share the opportunity with AWS for visibility only, you will not receive deal assistance or support.',
                },
              ]}
              required
              name={'partnerNeedType'}
              onChange={(e) => handlePartnerNeedTypeChange(e, setFieldValue)}
              disabled={
                !isFieldEditable({
                  name: 'partnerNeedType',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.partnerNeedType !==
            AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP && (
            <Grid item xs={12}>
              <MultiSelectFormField
                fieldId={'primaryNeedsFromAws'}
                label={'Partner specific needs from AWS'}
                dataId={
                  coSellAceOpportunityFormFieldsDataId.PRIMARY_NEEDS_FROM_AWS
                }
                options={awsEnumListMap?.['primaryNeedsFromAws[]']}
                required={
                  values.partnerNeedType !==
                  AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
                }
                filterSelectedOptions={true}
                disabled={
                  !isFieldEditable({
                    name: 'primaryNeedsFromAws',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RadioCheckboxGroupFormField
              name="opportunityType"
              id="opportunityType"
              label="Opportunity type"
              radioOptions={getOpportunityTypeOptions(
                awsEnumListMap?.['opportunityType'],
              )}
              onChange={(e) => handleOpportunityTypeChange(e, setFieldValue)}
              required
              disabled={
                !isFieldEditable({
                  name: 'opportunityType',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.opportunityType === AceOpportunityTypeEnum.EXPANSION ||
          values.opportunityType === AceOpportunityTypeEnum.FLAT_RENEWAL ? (
            <Grid item xs={12}>
              <TextFormField
                label="Parent co-sell ID"
                dataId={coSellAceOpportunityFormFieldsDataId.PARENT_CO_SELL_ID}
                name="parentOppId"
                disabled={
                  !isFieldEditable({
                    name: 'parentOppId',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="projectTitle"
              label="Partner project title"
              dataId={coSellAceOpportunityFormFieldsDataId.PROJECT_TITLE}
              multiline={true}
              maxLength={255}
              rows={6}
              required
              disabled={
                !isFieldEditable({
                  name: 'projectTitle',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId={'salesActivities'}
              label={'Sales activities'}
              dataId={coSellAceOpportunityFormFieldsDataId.SALES_ACTIVITIES}
              options={awsEnumListMap?.['project.salesActivities[]']}
              disabled={
                !isFieldEditable({
                  name: 'salesActivities',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              // Sales activities is required when the partner needs support from AWS.
              required={
                values.partnerNeedType !==
                AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              helperText="Choose sales activities that have happened with the end-customer."
              filterSelectedOptions={true}
            />
          </Grid>
          <Grid item xs={12}>
            {!ampliloading && showAiGenerateButton ? (
              <AiTextFormField
                name="customerBusinessProblem"
                dependsOnFields={['customerWebsite']}
                label="Customer business problem"
                dataId={
                  coSellAceOpportunityFormFieldsDataId.CUSTOMER_BUSINESS_PROBLEM
                }
                multiline
                maxLength={599}
                minLength={20}
                // placeholder="Add your own description, or select Generate with Tackle AI to get started"
                required
                helperText="Describe the business problem that most closely aligns to your customer’s use case. Must be at least 20 characters."
                disabled={
                  !isFieldEditable({
                    name: 'customerBusinessProblem',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            ) : (
              <TextFormField
                name="customerBusinessProblem"
                label="Customer business problem"
                dataId={
                  coSellAceOpportunityFormFieldsDataId.CUSTOMER_BUSINESS_PROBLEM
                }
                multiline
                rows={6}
                maxLength={32000}
                minLength={50}
                required
                helperText="Describe the business problem that most closely aligns to your customer’s use case. Must be at least 20 characters."
                disabled={
                  !isFieldEditable({
                    name: 'customerBusinessProblem',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Solutions
              status={opportunity?.lifeCycle?.reviewStatus}
              setFieldValue={setFieldValue}
            />
          </Grid>
          {values.solutions?.includes(OTHER) ? (
            <Grid item xs={12}>
              <TextFormField
                name="otherSolutionDescription"
                label="Other solution offered"
                dataId={
                  coSellAceOpportunityFormFieldsDataId.OTHER_SOLUTION_OFFERED
                }
                required
                disabled={
                  !isFieldEditable({
                    name: 'otherSolutionDescription',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          {/* comment out AWS products for now 11/06/2024 */}
          {/* <Grid item xs={12}>
            <MultiSelectFormField
              fieldId={'awsProducts'}
              label={'AWS products'}
              options={[]}
              disabled={
                !isFieldEditable({
                  name: 'awsProducts',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
              // Sales activities is required when the partner needs support from AWS.
              required={
                false
                // values.partnerNeedType !==
                // AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              helperText="Choose one or more AWS products that will be utilized to solve the customer's business problem. Adding products enables AWS to connect you with the right support on this opportunity."
              filterSelectedOptions={true}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextFormField
              name="nextStep"
              label="Next step"
              dataId={coSellAceOpportunityFormFieldsDataId.NEXT_STEP}
              rows={6}
              multiline
              placeholder="Describe the next steps for this opportunity"
              maxLength={255}
              disabled={
                !isFieldEditable({
                  name: 'nextStep',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="customerUseCase"
              filterSelectedOptions={true}
              label="Use case"
              options={awsEnumListMap?.['project.customerUseCase']}
              required
              disabled={
                !isFieldEditable({
                  name: 'customerUseCase',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="deliveryModels"
              filterSelectedOptions={true}
              label="Delivery model"
              dataId={coSellAceOpportunityFormFieldsDataId.DELIVERY_MODEL}
              options={awsEnumListMap?.['project.deliveryModels[]']}
              helperText="Indicate one or more of the most applicable deployment or consumption model for your solution or service."
              required
              disabled={
                !isFieldEditable({
                  name: 'deliveryModel',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="apnPrograms"
              label={'APN programs'}
              dataId={coSellAceOpportunityFormFieldsDataId.APN_PROGRAMS}
              options={awsEnumListMap?.['project.apnPrograms[]']}
              filterSelectedOptions={true}
              required={false}
              disabled={
                !isFieldEditable({
                  name: 'apnPrograms',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DateFormField
              name="targetCloseDate"
              label="Target close date"
              dataId={coSellAceOpportunityFormFieldsDataId.TARGET_CLOSE_DATE}
              required
              disabled={
                !isFieldEditable({
                  name: 'targetCloseDate',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid xs={12} item style={{ marginTop: '24px' }}>
            <ExpectedCustomerSpendFieldSet />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 3: Marketing details */}
        <UnifiedOpportunityFormSection
          title="Marketing details"
          titleIcon={<AssignmentIcon />}
          dataId={coSellCreateEditPageDataId.MARKETING_DETAILS_SECTION_HEADER}
        >
          <Grid item xs={12}>
            <RadioCheckboxGroupFormField
              label={'Opportunity source'}
              name="marketingSource"
              required
              id={coSellAceOpportunityFormFieldsDataId.OPPORTUNITY_SOURCE}
              radioOptions={getOpportunitySourceOptions(
                awsEnumListMap?.['marketing.source'],
              )}
              onChange={(e) => handleOpportunitySourceChange(e, setFieldValue)}
              disabled={
                !isFieldEditable({
                  name: 'marketingSource',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>

          {isOppFromMarketingActivity && (
            <>
              <Grid item xs={12}>
                <TextFormField
                  name="campaignName"
                  label="Marketing campaign"
                  dataId={
                    coSellAceOpportunityFormFieldsDataId.MARKETING_CAMPAIGN
                  }
                  disabled={
                    !isFieldEditable({
                      name: 'campaignName',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  fieldId={'marketingActivityUseCases'}
                  label={'Marketing activity use case'}
                  dataId={
                    coSellAceOpportunityFormFieldsDataId.MARKETING_ACTIVITY_USE_CASE
                  }
                  options={awsEnumListMap?.['marketing.useCases[]']}
                  disabled={
                    !isFieldEditable({
                      name: 'marketingActivityUseCases',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                  required={false}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  fieldId={'marketingActivityChannel'}
                  label={'Marketing activity channel'}
                  dataId={
                    coSellAceOpportunityFormFieldsDataId.MARKETING_ACTIVITY_CHANNEL
                  }
                  options={awsEnumListMap?.['marketing.channels[]']}
                  disabled={
                    !isFieldEditable({
                      name: 'marketingActivityChannel',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                  required={false}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioCheckboxGroupFormField
                  id="isMarketingDevelopmentFunded"
                  label="Marketing development funds"
                  radioOptions={getAwsFundingUsedOptions(
                    awsEnumListMap?.['marketing.awsFundingUsed'],
                  )}
                  required
                  name={'isMarketingDevelopmentFunded'}
                  disabled={
                    !isFieldEditable({
                      name: 'isMarketingDevelopmentFunded',
                      status: opportunity?.lifeCycle?.reviewStatus,
                    })
                  }
                />
              </Grid>
            </>
          )}
        </UnifiedOpportunityFormSection>

        {/* Section 4: Additional Details */}
        <UnifiedOpportunityFormSection
          title="Additional details"
          titleIcon={<AssignmentIcon />}
          dataId={coSellCreateEditPageDataId.ADDITIONAL_DETAILS_SECTION_HEADER}
        >
          <Grid item xs={12}>
            <SingleSelectFormField
              // aria-disabled={
              // }
              fieldId="competitiveTracking"
              data-testid="competitiveTracking"
              label="Competitive tracking"
              onChange={() => setFieldValue('otherCompetitorNames', null)}
              options={awsEnumListMap?.['project.competitorName']}
              disabled={
                !isFieldEditable({
                  name: 'competitiveTracking',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          {values.competitiveTracking === CompetitiveTrackingEnum.OTHER ? (
            <Grid item xs={12}>
              <TextFormField
                name="otherCompetitorNames"
                label="Other competitors"
                dataId={coSellAceOpportunityFormFieldsDataId.OTHER_COMPETITORS}
                disabled={
                  !isFieldEditable({
                    name: 'otherCompetitorNames',
                    status: opportunity?.lifeCycle?.reviewStatus,
                  })
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="awsAccountId"
              label="AWS account ID"
              dataId={coSellAceOpportunityFormFieldsDataId.AWS_ACCOUNT_ID}
              helperText="12 digit number. For example: 123456789012"
              disabled={
                !isFieldEditable({
                  name: 'awsAccountId',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <AdditionalComments status={opportunity?.lifeCycle?.reviewStatus} />
          </Grid>
        </UnifiedOpportunityFormSection>
        {/* Section 5: Customer contact */}
        <UnifiedOpportunityFormSection
          title="Customer contact"
          titleIcon={<PersonIcon />}
          dataId={coSellCreateEditPageDataId.CUSTOMER_CONTACT_SECTION_HEADER}
        >
          <Grid item xs={12}>
            <TextFormField
              name="customerFirstName"
              label="Customer first name"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_FIRST_NAME}
              disabled={
                !isFieldEditable({
                  name: 'customerFirstName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerLastName"
              label="Customer last name"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_LAST_NAME}
              disabled={
                !isFieldEditable({
                  name: 'customerLastName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerTitle"
              label="Customer title"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_TITLE}
              disabled={
                !isFieldEditable({
                  name: 'customerTitle',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerEmail"
              label="Customer email"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_EMAIL}
              disabled={
                !isFieldEditable({
                  name: 'customerEmail',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="customerPhone"
              label="Customer phone"
              dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_PHONE}
              disabled={
                !isFieldEditable({
                  name: 'customerPhone',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 6: Partner contact */}
        <UnifiedOpportunityFormSection
          title="Partner contact"
          titleIcon={<PersonIcon />}
          tooltip="AWS sales team may reach out to this contact in regard to the opportunity."
          dataId={coSellCreateEditPageDataId.PARTNER_CONTACT_SECTION_HEADER}
        >
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactFirstName"
              label="Primary contact first name"
              dataId={
                coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_FIRST_NAME
              }
              disabled={
                !isFieldEditable({
                  name: 'primaryContactFirstName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactLastName"
              label="Primary contact last name"
              dataId={
                coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_LAST_NAME
              }
              disabled={
                !isFieldEditable({
                  name: 'primaryContactLastName',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactTitle"
              label="Primary contact title"
              dataId={
                coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_TITLE
              }
              disabled={
                !isFieldEditable({
                  name: 'primaryContactTitle',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactEmail"
              label="Primary contact email"
              dataId={
                coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_EMAIL
              }
              disabled={
                !isFieldEditable({
                  name: 'primaryContactEmail',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="primaryContactPhone"
              label="Primary contact phone"
              dataId={
                coSellAceOpportunityFormFieldsDataId.PRIMARY_CONTACT_PHONE
              }
              disabled={
                !isFieldEditable({
                  name: 'primaryContactPhone',
                  status: opportunity?.lifeCycle?.reviewStatus,
                })
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>
      </div>
    </>
  );
};

export default AceOpportunityFormFields;
