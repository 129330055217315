import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import {
  CoSellDashboard,
  CoSellOpportunity,
  CoSellOpportunityForm,
} from 'pages';
import { WithCoSellPackages } from 'pages/CoSell/utilities/withCosellPackages';

/**
 * Nested routes for CoSell V2
 * This was added to allow users to toggle between CoSell V2 and V3
 */
export const CoSellV2Root = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        component={WithCoSellPackages(CoSellDashboard)}
        exact
        path={path}
      />
      <Route
        component={WithCoSellPackages(CoSellOpportunityForm)}
        exact
        path={`${path}/opportunity/:cloud`}
      />
      <Route
        component={WithCoSellPackages(CoSellOpportunity)}
        exact
        path={`${path}/opportunity/:cloud/:opportunityId`}
      />
      <Redirect to={path} />
    </Switch>
  );
};
