import React from 'react';
import useStyles from './Header.styles';
import { Box } from 'vendor/material';
import { Button, Link, Tooltip } from '@tackle-io/platform-ui';
import AWSLogo from 'images/aws-logo';
import { StatusTag } from 'pages/Contracts/components/StatusTag';
import CaretLeft from './assets/DetailsIcon';
import { useSingleContractData } from '../../providers/SingleContractDataProvider';
import { LoadingMetrics } from 'pages/Contracts/components/ContractsMetrics/components/LoadingMetrics';
import { OfferTag } from 'pages/Contracts/components/OfferTag/OfferTag';
import { ampli } from 'utils/analytics/ampli';

const displayCloudContract = (contract): boolean => {
  if (contract?.cloudContractStatus) {
    if (contract?.contractStatus) {
      return (
        contract.cloudContractStatus.toUpperCase() !==
        contract?.contractStatus.toUpperCase()
      );
    }
  }
  return contract?.cloudContractStatus;
};

const displayAmendButton = (contract): boolean => {
  return contract?.contractStatus === 'Active';
};

const displayAddUsageButton = (contract): boolean => {
  return contract?.contractStatus === 'Active';
};

const Header: React.FC = () => {
  const classes = useStyles();

  const { contract, loading } = useSingleContractData();

  const handleDownloadArtifactClick = async () => {
    await ampli.bookableArtifactDownloaded({
      marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
      contract_status: contract?.contractStatus,
      offer_origination: contract?.offerType,
      pricing_model: contract?.pricingModel,
      tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
    });
  };

  const handleAddUsageClick = async () => {
    await ampli.addUsageStarted({
      marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
      contract_status: contract?.contractStatus,
      offer_origination: contract?.offerType,
      pricing_model: contract?.pricingModel,
      tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
    });
  };

  const handleAmendClick = async () => {
    await ampli.amendContractStarted({
      marketplace: contract?.cloudMarketplace as 'aws' | 'azure' | 'gcp',
      contract_status: contract?.contractStatus,
      offer_origination: contract?.offerType,
      pricing_model: contract?.pricingModel,
      tackle_managed: contract?.productAndPricing?.isListingManagedByTackle,
    });
  };

  if (loading) {
    return <LoadingMetrics />;
  }

  return (
    <Box
      flexDirection={'column'}
      alignContent={'flex-start'}
      className={classes.headerContainer}
    >
      <Box display={'flex'} gridGap={8}>
        <Link to="/cloud-contracts" underline="none" disableStyles>
          <CaretLeft />
        </Link>
        <span className={classes.backText}>Contracts</span>
      </Box>
      <Box display={'flex'} justifyContent="space-between" alignItems="center">
        <Box className={classes.leftSection}>
          <Box className={classes.contractNameAndLogo}>
            <AWSLogo
              className={classes.cloudLogo}
              disabled={false}
              width={'32'}
            />
            {/* Adding temp h1 tag for preview icon to be injected via third party - remove on ENG-8750 */}
            <span className={classes.buyer}>
              <h1 className={classes.previewTag}>
                {contract?.companyName ? (
                  contract?.companyName
                ) : (
                  <Tooltip
                    content="Pending from the marketplace"
                    position="bottom"
                  >
                    <span>--</span>
                  </Tooltip>
                )}
              </h1>
            </span>
          </Box>
          {contract?.offerName !== "''" ? (
            <Box>
              <span className={classes.offerName}>
                {contract?.offerName ? (
                  contract?.offerName
                ) : (
                  <Tooltip
                    content="Pending from the marketplace"
                    position="bottom"
                  >
                    <span>--</span>
                  </Tooltip>
                )}
              </span>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box className={classes.rightSection}>
          <Button
            variant="outlined"
            appearance="primary"
            onClick={handleDownloadArtifactClick}
          >
            Download bookable artifact
          </Button>
          {displayAddUsageButton(contract) && (
            <Button
              variant="outlined"
              appearance="primary"
              onClick={handleAddUsageClick}
            >
              Add usage
            </Button>
          )}
          {displayAmendButton(contract) && (
            <Button appearance="primary" onClick={handleAmendClick}>
              Amend
            </Button>
          )}
        </Box>
      </Box>
      <Box className={classes.badges}>
        <OfferTag offerType={contract?.offerType} />
        <StatusTag label={contract?.contractStatus} />
        {displayCloudContract(contract) ? (
          <StatusTag label={contract?.cloudContractStatus} />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Header;
