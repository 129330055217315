import {
  CurrencyFormField,
  SingleSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';

import { Grid, makeStyles } from 'vendor/material';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { Link } from '@tackle-io/platform-ui';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #EBECF0',
    height: '40px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#5E6C84',
    marginBottom: theme.spacing(1),
  },
  helperText: {
    marginBlock: `${theme.spacing(1)}px`,
    color: '#5E6C84',
    fontSize: '12px',
    lineHeight: '20px',
    '&>a': {
      color: '#0052CC',
    },
  },
}));

export const ExpectedCustomerSpendFieldSet = () => {
  const classes = useStyles();
  const { awsEnumListMap } = useOpportunity();
  return (
    <Grid container spacing={2}>
      <Grid className={classes.title} xs={12}>
        Expected customer spend
      </Grid>
      <Grid item xs={12}>
        <CurrencyFormField
          name="expectedCustomerSpendAmount"
          label="Amount"
          data-id={
            coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_AMOUNT
          }
          required
        />
        <p className={classes.helperText}>
          Your customer's anticipated monthly usage based on standard pricing.
          Use{' '}
          <Link external to="https://calculator.aws">
            AWS Pricing Calculator
          </Link>{' '}
          to help you create a cost estimate for your customer's total spend. If
          only annual recurring revenue (ARR) is available, distribute it across
          12 months for an average monthly value.
        </p>
      </Grid>
      <Grid item xs={12}>
        <SingleSelectFormField
          data-testid="expectedCustomerSpendCurrencyCode"
          fieldId="expectedCustomerSpendCurrencyCode"
          label="Currency"
          data-id={
            coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_CURRENCY_CODE
          }
          options={
            awsEnumListMap?.['project.expectedCustomerSpend[].currencyCode']
          }
          required
        />
      </Grid>
      <Grid item xs={12}>
        <SingleSelectFormField
          data-testid="expectedCustomerSpendFrequency"
          /** For now the only valid value is Monthy, and this cannot be changed by the vendor */
          disabled
          fieldId="expectedCustomerSpendFrequency"
          label="Frequency"
          data-id={
            coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_FREQUENCY
          }
          options={
            awsEnumListMap?.['project.expectedCustomerSpend[].frequency']
          }
          helperText="The frequency that your customer is expected to spend the expected amount."
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          name="expectedCustomerSpendTargetCompany"
          label="Target company"
          dataId={
            coSellAceOpportunityFormFieldsDataId.EXPECTED_CUSTOMER_SPEND_TARGET_COMPANY
          }
          /** For the moment the only acceptable value is AWS, and this cannot be changed by the vendor */
          disabled
          defaultValue="AWS"
          required
          helperText="The company or organization that is expected to make the expected spend."
        />
      </Grid>
    </Grid>
  );
};
