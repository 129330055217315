import { ErrorBoundary, Page } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import React from 'react';
import useStyles from './ContractDetails.styles';
import { useDocumentTitle } from 'hooks';
import Header from './components/Header/Header';
import Summary from './components/Summary/Summary';
import ContractTabs from './components/ContractTabs/ContractTabs';
import { SingleContractDataProvider } from './providers/SingleContractDataProvider';

const ContractDetails: React.FC = () => {
  const classes = useStyles();

  useDocumentTitle('Cloud Contract Detail');
  return (
    <ContractDetailsContainer>
      <Page className={classes.container}>
        <Header />
        <Summary />
        <ContractTabs />
      </Page>
    </ContractDetailsContainer>
  );
};

export const ContractDetailsContainer = ({ children }) => {
  return (
    <ErrorBoundary
      renderError={() => <ErrorPage title="Cloud Contract Detail" />}
    >
      <SingleContractDataProvider>{children}</SingleContractDataProvider>
    </ErrorBoundary>
  );
};

export default ContractDetails;
