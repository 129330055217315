import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
} from '../../../../vendor/material/index';
import { useTextFieldStyles } from '@tackle-io/platform-ui';
import { GcpMetricDiscountMode } from './GcpMetricDiscountMode';
import { useFormContext } from 'react-hook-form';
import { calculateNewDiscountPrice } from './gcpUsageDimensionUtils';
import { usageDimensionsFormPath } from '../../utils';

interface GcpUsageDimensionNewDiscountProps {
  selectedDiscountMode: GcpMetricDiscountMode;
  equalDiscount: number;
  index: number;
}

const determineDiscountPercentageToUse = (
  discountMode: GcpMetricDiscountMode,
  equalDiscount: number,
  dimensionDiscount: number,
): number => {
  return discountMode === GcpMetricDiscountMode.ApplyDiscountEqually
    ? equalDiscount
    : dimensionDiscount;
};

const getDiscountPriceDisplay = (
  discount: number,
  discountPrice: number,
): string => {
  return !discount ? '' : `$${discountPrice.toFixed(2)}`;
};

const GcpUsageDimensionNewDiscount: React.FC<
  GcpUsageDimensionNewDiscountProps
> = ({ selectedDiscountMode, equalDiscount, index }) => {
  const styles = useTextFieldStyles();
  const { watch } = useFormContext();

  const usageDimension = watch(`${usageDimensionsFormPath}.${index}`);

  const discountPercentage = determineDiscountPercentageToUse(
    selectedDiscountMode,
    equalDiscount,
    usageDimension.discountPercentage,
  );

  const discountPrice = calculateNewDiscountPrice(
    usageDimension.price,
    discountPercentage,
  );

  const discountPriceDisplay = getDiscountPriceDisplay(
    discountPercentage,
    discountPrice,
  );

  return (
    <FormControl>
      <InputLabel className={styles.inputLabel}>New discount price</InputLabel>
      <Box mt={1} ml={1.5}>
        <Typography>{discountPriceDisplay}</Typography>
      </Box>
    </FormControl>
  );
};

export default GcpUsageDimensionNewDiscount;
