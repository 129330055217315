import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';

const useOpportunityEventsQuery = (opportunityId: string) => {
  const {
    data: opportunityEvents,
    error: opportunityEventsError,
    isLoading: isOpportunityEventsLoading,
  } = useQuery({
    queryKey: [opportunityId, 'events'],
    queryFn: () => coSellClient.getOpportunityEvents(opportunityId),
    enabled: !!opportunityId,
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
  });

  return {
    opportunityEvents,
    opportunityEventsError,
    isOpportunityEventsLoading,
  };
};

export default useOpportunityEventsQuery;
