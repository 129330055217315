import React, { SetStateAction, Dispatch } from 'react';
import { Box } from 'vendor/material';
import { EditModal } from '../EditModal';
import AceOpportunityDetailsHeader from '../../pages/UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/AceOpportunityDetailsHeader';
import AceOpportunityFormFields from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormFields';
import { Formik } from 'formik';
import { convertOpportunityResponseToAceFormValues } from '../../utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { aceOpportunityFormValidationSchema } from '../UnifiedOpportunityForm/AceOpportunityForm/aceOpportunityFormValidationSchema';
import { UpdateAceOpportunityFormValues } from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  convertAceFormValuesToUpdateRequest,
  // getSoftwareRevenue,
} from '../../utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import { useOpportunity } from '../../pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { ampli } from 'utils/analytics/ampli';
import { getEditModalTackleOperationId } from 'packages/cosell/api/utils';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { EditModalType } from './RightRailButtonSection';
// import { removeUndefinedOrEmptyObjectProperties } from '../../utilities/typeConverters/utils';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';

interface RightRailEditModalProps {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType>>;
}

const renderModal = ({
  editModalType,
  setEditModalOpen,
  opportunity,
  onSubmit,
}: {
  editModalType: EditModalType;
  opportunity: AceOpportunityResponse;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType>>;
  onSubmit: (values: UpdateAceOpportunityFormValues) => Promise<unknown>;
}) => {
  switch (editModalType) {
    case EditModalType.EDIT_LAUNCHED:
      return (
        <EditModal
          open={true}
          onClose={() => setEditModalOpen(null)}
          onSubmit={(values) => onSubmit(values)}
          title="Edit launched co-sell opportunity"
        >
          <div>
            <Box mt={2} mb={3}>
              <AceOpportunityDetailsHeader
                title={opportunity?.customer?.account?.companyName}
                source={opportunity?.source}
                lifeCycle={opportunity?.lifeCycle}
              />
            </Box>
            <AceOpportunityFormFields editModalType={editModalType} />
          </div>
        </EditModal>
      );
    case EditModalType.LAUNCH:
      return (
        <EditModal
          open={true}
          onClose={() => setEditModalOpen(null)}
          onSubmit={(values) => onSubmit(values)}
          submitLabel="Launch"
          title={'Launch this co-sell opportunity with ACE'}
        >
          <AceOpportunityFormFields editModalType={editModalType} />
        </EditModal>
      );
    case EditModalType.CLOSE_LOST:
      return (
        <EditModal
          open={true}
          onClose={() => setEditModalOpen(null)}
          onSubmit={(values) => onSubmit(values)}
          submitLabel="Close lost"
          title={'Close this co-sell opportunity with ACE as lost'}
        >
          <AceOpportunityFormFields editModalType={editModalType} />
        </EditModal>
      );
    default:
      return null;
  }
};

const RightRailEditModal: React.FC<RightRailEditModalProps> = ({
  editModalType,
  setEditModalOpen,
}: {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType>>;
}) => {
  const { opportunityId } = useOpportunity();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });

  const opportunity = aceOpportunityQuery?.data;

  const handleEditOpportunity = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    const body = convertAceFormValuesToUpdateRequest(values);

    // const requestBody = {
    //   lifeCycle: {
    //     targetCloseDate: values.targetCloseDate,
    //   },
    //   softwareRevenue: getSoftwareRevenue(values),
    // };

    // const updatedBody = removeUndefinedOrEmptyObjectProperties(body);
    // TODO: comment this fn out since it is removing number values for now (e.g.: expectedMonthlyAwsRevenue has been removed after the fn call);
    const tackleOperationId = getEditModalTackleOperationId(editModalType);

    // launch update request body does not have the whole object
    // TODO: add a new update request body Type for launch
    updateAceOpportunity.mutateAsync({
      requestBody: body,
      tackleOperationId,
    });
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    try {
      await handleEditOpportunity(values);
      ampli.outboundSubmitted({ cloud: 'aws' });
      setEditModalOpen(null);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={convertOpportunityResponseToAceFormValues(opportunity)}
      validationSchema={aceOpportunityFormValidationSchema}
      onSubmit={() => {
        // noOp because we use the button outside of the form to submit
        // noop
      }}
    >
      {renderModal({
        editModalType,
        setEditModalOpen,
        opportunity,
        onSubmit: handleSubmitAceOpportunityToCloud,
      })}
    </Formik>
  );
};

export default RightRailEditModal;
