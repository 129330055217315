import React, { useState } from 'react';
import { Refresh } from 'mdi-material-ui';
import useStyles from './RightRailButtonSection.style';
import { Box } from 'vendor/material';
import {
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
} from '../../types/enums';
import Button from '../Button/Button';
import { useHistory } from 'react-router-dom';
import RightRailEditModal from './RightRailEditModal';
import { useOpportunity } from '../../pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { TackleOperationId } from 'packages/cosell/api/utils';
import { useIsSandboxEnvironment } from '../../../../../hooks';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useCanUseAwsFeatures } from '../../hooks/useCanUseAwsFeatures/useCanUseAwsFeatures';
import { coSellDetailsPageDataId } from '../../utilities/intercomEnums';

interface RightRailButtonSectionProps {
  status: AceOpportunityReviewStatusEnum;
  stage: AceOpportunityStageEnum;
  hasPendingRequest: boolean;
}

export enum EditModalType {
  EDIT_LAUNCHED = 'Edit launched',
  LAUNCH = 'Launch',
  CLOSE_LOST = 'Close lost',
}

const RightRailButtonSection: React.FC<RightRailButtonSectionProps> = ({
  status,
  stage,
  hasPendingRequest,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { opportunityId } = useOpportunity();
  const isSandboxEnvironment = useIsSandboxEnvironment();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });

  const variantByStatus =
    status === AceOpportunityReviewStatusEnum.ACTION_REQUIRED
      ? 'contained'
      : 'outlined';
  const [editModalOpen, setEditModalOpen] = useState<EditModalType>(null);
  const showSandboxAcceptRejectButtons =
    isSandboxEnvironment && status === AceOpportunityReviewStatusEnum.SUBMITTED;

  const { canUse } = useCanUseAwsFeatures();

  const handleUpdateAcceptRejectOpportunity = async (
    operationID: TackleOperationId,
  ) => {
    try {
      await coSellClient.acceptOrRejectOpportunity({
        opportunityId,
        tackleOperationId: operationID,
      });
      // refresh the current page
      history.go(0);
    } catch (error) {
      throw error;
    }
  };

  // after the opportunity is approved,
  // show the launch and close lost buttons in the early stages
  const isEarlyStage = [
    AceOpportunityStageEnum.PROSPECT,
    AceOpportunityStageEnum.QUALIFIED,
    AceOpportunityStageEnum.TECHNICAL_VALIDATION,
    AceOpportunityStageEnum.BUSINESS_VALIDATION,
    AceOpportunityStageEnum.COMMITTED,
  ].includes(stage);

  const handleRedirectToEditPage = () => {
    const currentUrl = history.location.pathname;
    const redirectUrl = `${currentUrl}/edit`;
    history.push(redirectUrl);
  };

  const updateOpportunityFromDraftToSubmitted = async () => {
    updateAceOpportunity.mutateAsync({
      requestBody: {},
      tackleOperationId: TackleOperationId.START_ENGAGEMENT,
    });
  };

  return (
    <Box className={classes.root}>
      {hasPendingRequest && (
        <Button
          variant="contained"
          data-id={coSellDetailsPageDataId.REFRESH_OPPORTUNITY}
          appearance="primary"
          onClick={() => {
            aceOpportunityQuery.refetch();
          }}
          startIcon={<Refresh />}
        >
          Refresh
        </Button>
      )}
      {!showSandboxAcceptRejectButtons && !hasPendingRequest && (
        <Button
          disabled={!canUse.opportunities.edit}
          variant={variantByStatus}
          data-id={coSellDetailsPageDataId.EDIT_OPPORTUNITY}
          appearance="primary"
          onClick={() => {
            if (stage === AceOpportunityStageEnum.LAUNCHED) {
              setEditModalOpen(EditModalType.EDIT_LAUNCHED);
            } else {
              handleRedirectToEditPage();
            }
          }}
        >
          Edit co-sell opportunity
        </Button>
      )}
      {!showSandboxAcceptRejectButtons &&
        !hasPendingRequest &&
        status === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION && (
          <Button
            disabled={!canUse.opportunities.submitToCloud}
            variant="contained"
            appearance="primary"
            data-id={coSellDetailsPageDataId.SUBMIT_TO_CLOUD}
            onClick={updateOpportunityFromDraftToSubmitted}
            loading={updateAceOpportunity.isLoading}
          >
            Submit to cloud
          </Button>
        )}
      {status === AceOpportunityReviewStatusEnum.APPROVED &&
        isEarlyStage &&
        !hasPendingRequest && (
          <Box className={classes.actionButton}>
            <Button
              disabled={!canUse.opportunities.close}
              variant="contained"
              data-id={coSellDetailsPageDataId.LAUNCH_OPPORTUNITY}
              appearance="primary"
              onClick={() => {
                setEditModalOpen(EditModalType.LAUNCH);
              }}
            >
              Launch
            </Button>
            <Button
              disabled={!canUse.opportunities.close}
              variant="contained"
              data-id={coSellDetailsPageDataId.CLOSE_LOST}
              appearance="destructive"
              onClick={() => {
                setEditModalOpen(EditModalType.CLOSE_LOST);
              }}
            >
              Close lost
            </Button>
          </Box>
        )}
      {showSandboxAcceptRejectButtons &&
        !hasPendingRequest &&
        status === AceOpportunityReviewStatusEnum.SUBMITTED && (
          <Box className={classes.actionButton}>
            <Button
              variant="contained"
              data-id={coSellDetailsPageDataId.ACCEPT_OPPORTUNITY}
              appearance="primary"
              onClick={() =>
                handleUpdateAcceptRejectOpportunity(
                  TackleOperationId.ACCEPT_OPPORTUNITY,
                )
              }
            >
              Accept
            </Button>
            <Button
              variant="contained"
              data-id={coSellDetailsPageDataId.REJECT_OPPORTUNITY}
              appearance="destructive"
              onClick={() =>
                handleUpdateAcceptRejectOpportunity(
                  TackleOperationId.REJECT_OPPORTUNITY,
                )
              }
            >
              Reject
            </Button>
          </Box>
        )}
      {editModalOpen && (
        <RightRailEditModal
          editModalType={editModalOpen}
          setEditModalOpen={setEditModalOpen}
        />
      )}
    </Box>
  );
};

export default RightRailButtonSection;
