import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { useOpportunity } from '../UnifiedOpportunityForm/providers/OpportunityProvider';
import AceOpportunityDetails from './AceOpportunityDetails/AceOpportunityDetails';
import { Loader } from '@tackle-io/platform-ui';
import useOpportunityEventsQuery from '../../hooks/useOpportunityEventsQuery';

const UnifiedOpportunityDetails = () => {
  const { isSaasDocumentationRequiredToLaunch, opportunityId } =
    useOpportunity();
  const {
    opportunityEvents,
    isOpportunityEventsLoading,
    opportunityEventsError,
  } = useOpportunityEventsQuery(opportunityId);
  const events = opportunityEvents?.events || [];

  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });
  const opportunity = aceOpportunityQuery?.data;
  const hasAceRequestQueueError = !!opportunity?.metadata?.hasErrors;

  // this opportunityError property from useQuery
  if (aceOpportunityQuery.isError) {
    return <div>Error loading opportunity</div>;
  }
  // TODO: we may still be able to show the opportunity details even if the opportunity event has error
  if (opportunityEventsError) {
    return <div>Error loading opportunity events</div>;
  }
  // this hasErrors property from metadata, in this case we have opportunity data to show
  if (hasAceRequestQueueError) {
    // TODO: this hasErrors indicates that the most recent request has error in aceRequestQueueHandler,
    // but we can still show the existing opportunity details, maybe add warning message somewhere
    return <div>Encountered errors while creating an opportunity in AWS</div>;
  }

  if (
    aceOpportunityQuery.isLoading ||
    updateAceOpportunity.isLoading ||
    isOpportunityEventsLoading ||
    !opportunity?.partnerOpportunityIdentifier
  ) {
    return <Loader />;
  }

  if (!opportunity) {
    return <div>No opportunity found</div>;
  }

  return (
    <AceOpportunityDetails
      opportunity={opportunity}
      requiresSaasDocumentationToLaunch={isSaasDocumentationRequiredToLaunch({
        deliveryModels: opportunity.project?.deliveryModels,
      })}
      opportunityEvents={events}
    />
  );
};

export default UnifiedOpportunityDetails;
