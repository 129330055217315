import React, { ChangeEvent, useState } from 'react';
import { Banner, RadioGroup } from '@tackle-io/platform-ui';
import { Box, Grid, Typography } from 'vendor/material';
import { GcpMetricDiscountMode } from './GcpMetricDiscountMode';
import GcpUsageDimension from './GcpUsageDimension';
import { useFormContext } from 'react-hook-form';
import { FormUsageDimension } from '../../../../pages/PrivateOffers/utils/formatFormData';
import TextField from '../../TextField/TextField';
import {
  FormMetadataUsageDimension,
  usageDimensionsFormPath,
} from '../../utils';
import { toFinite } from 'lodash';
import { UsageDimensionDiscountsSet } from './gcpUsageDimensionUtils';

interface GcpUsageDimensionsProps {
  usageDimensions: FormMetadataUsageDimension[];
}

const discountModes = [
  {
    label: 'No discount applied',
    value: GcpMetricDiscountMode.NoDiscount,
  },
  {
    label: 'Apply discount to all dimensions equally',
    value: GcpMetricDiscountMode.ApplyDiscountEqually,
  },
  {
    label: 'Apply discount to each dimension individually',
    value: GcpMetricDiscountMode.ApplyDiscountIndividually,
  },
];

const GcpUsageDimensions: React.FC<GcpUsageDimensionsProps> = ({
  usageDimensions,
}) => {
  const discountsSet = new UsageDimensionDiscountsSet(usageDimensions);

  const [selectedDiscountMode, setSelectedDiscountMode] =
    useState<GcpMetricDiscountMode>(
      discountsSet.allDiscountsAreEqual()
        ? discountsSet.discountsSetHasZeroOrNull()
          ? GcpMetricDiscountMode.NoDiscount
          : GcpMetricDiscountMode.ApplyDiscountEqually
        : GcpMetricDiscountMode.ApplyDiscountIndividually,
    );

  const [equalDiscount, setEqualDiscount] = useState<number>(
    discountsSet.allDiscountsAreEqual()
      ? discountsSet.getFirstDiscountPercentageValue() || 0.0
      : 0.0,
  );

  const { setValue } = useFormContext();

  const setDiscountPercentageToAllUsageDimensions = (
    discountPercentage: number,
  ) => {
    usageDimensions.forEach((_: FormUsageDimension, i: number) => {
      setValue(
        `${usageDimensionsFormPath}.${i}.discountPercentage`,
        discountPercentage,
      );
    });
  };

  const onDiscountModeChanged = (mode: GcpMetricDiscountMode) => {
    const setDiscountPercentagesAccordingToMode =
      mode === GcpMetricDiscountMode.NoDiscount ||
      mode === GcpMetricDiscountMode.ApplyDiscountIndividually
        ? () => setDiscountPercentageToAllUsageDimensions(0)
        : () => setDiscountPercentageToAllUsageDimensions(equalDiscount);

    setDiscountPercentagesAccordingToMode();
    setSelectedDiscountMode(mode);
  };

  const onEqualDiscountChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: newEqualDiscount },
    } = event;
    const newEqualDiscountAsNumber = toFinite(newEqualDiscount);

    setDiscountPercentageToAllUsageDimensions(newEqualDiscountAsNumber);
    setEqualDiscount(newEqualDiscountAsNumber);
  };

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Banner
          title="Dimension list price is from your Tackle listing"
          body="Tier pricing created in the Google Producer Portal is not shown or supported at this time. You can still add discounts to dimensions that have tier pricing. The discounts added will be applied equally to all tiers."
        />
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle1">
          Apply discounts to your dimension list pricing
        </Typography>
      </Box>
      <Box ml={0.5} mb={2}>
        <RadioGroup
          options={discountModes}
          value={selectedDiscountMode}
          onChange={onDiscountModeChanged}
          direction="vertical"
        />
      </Box>
      {selectedDiscountMode === GcpMetricDiscountMode.ApplyDiscountEqually && (
        <Box mb={2}>
          <Grid container>
            <Grid item md={2}>
              <TextField
                name="equal-discount"
                label="Discount to be applied"
                type="number"
                mode="numberformat"
                numberPrefix=""
                suffix="%"
                defaultValue={equalDiscount}
                onChange={onEqualDiscountChanged}
                decimalScale={2}
                fixedDecimalScale
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {usageDimensions.map((ud: FormUsageDimension, i: number) => {
        return (
          <GcpUsageDimension
            key={(ud as any).id}
            selectedDiscountMode={selectedDiscountMode}
            equalDiscount={equalDiscount}
            index={i}
            usageDimension={ud}
          />
        );
      })}
    </Box>
  );
};

export default GcpUsageDimensions;
